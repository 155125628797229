import { Field } from 'formik';
import React from 'react';

interface OwnProps {
  name: string;
  value?: any;
  onChange?: (name: string, value: string | number | null | undefined) => void;
}

interface Props extends OwnProps {
}

const InputHidden = (
  {
    name,
  }: Props,
) => (<Field type="hidden" name={name} />);

export default InputHidden;
