import React from 'react';
import InputDescription from 'src/component/Input/InputDescription';
import Select from 'react-select';
import InputLabel from 'src/component/Input/InputLabel';
import { SelectOption } from 'src/component/Input/InputSelect';

interface OwnProps {
  label?: string;
  name: string;
  description?: string;
  value: SelectOption;
  onChange: (value: string) => void;
  options: SelectOption[];
  disabled?: boolean;
  multiple?: boolean;
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

interface Props extends OwnProps {
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

const InputSelectControlled = (props: Props) => {
  const {
    name,
    description,
    label,
    options,
    disabled,
    multiple,
    value,
    onChange,
  } = props;

  const inputId = `inputSelect_${name}`;

  return (
    <div className="form-group">
      <InputLabel forInput={inputId} text={label} />
      <Select
        options={options}
        name={name}
        multi={multiple}
        disabled={disabled}
        value={value}
        onChange={(option: any) => {
          onChange(option.value);
        }}
        instanceId={inputId}
      />
      <InputDescription description={description} />
    </div>
  );
};

export default InputSelectControlled;
