import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import apiRequestBlob from 'src/api/apiRequestBlob';
import ClipArtCategoryListItem from './ClipArtCategoryListItem';
import ClipArtCategory from './ClipArtCategory';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ClipArtCategoryList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [categories, setCategories] = useState(
    [] as AdminBundle.Entity.API.ClipArtCategoryControllerV1.ClipArtCategoryListV1Response.ClipArtCategory[],
  );
  const [openedClipArtCategoryId, openClipArtCategoty] = useState(0);

  const browseClipArtCategory = (clipArtCategoryId: number) => {
    openClipArtCategoty(clipArtCategoryId);
  };

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.clipArtCategoryV1List,
        {},
        'data.list',
        'POST',
      ) as AdminBundle.Entity.API.ClipArtCategoryControllerV1.ClipArtCategoryListV1Response.ClipArtCategory[];
      setCategories(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const items = search
    ? categories.filter((category) => (
      (category.title || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (category.titleTrKey || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || category.clipArtCategoryId === parseInt(search, 10)
    ))
    : categories;

  const getTransaltions = async () => {
    const response = await apiRequestBlob(
      ApiEndpoint.clipArtCategoryV1GetTranslations,
      {},
      'data',
      'POST',
    ) as Blob;
    const translationsData = new Blob([response], { type: 'text/csv' });
    const blobUrl = window.URL.createObjectURL(translationsData);
    window.location.href = blobUrl;
  };

  return (
    <PageContent title="Clip art category list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link
            className="btn btn-success"
            to={getUrl(RouteName.clipArtCategoryAdd, { parentClipArtCategoryId: 0, sortOrder: 0 })}
          >
            Add new clip art category
          </Link>
          &nbsp;
          <button className="btn btn-success" onClick={getTransaltions}>Get translations</button>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Title</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((r) => (
                <ClipArtCategoryListItem
                  category={r}
                  depth={0}
                  key={r.clipArtCategoryId}
                  browseClipArtCategory={browseClipArtCategory}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-8">
          <ClipArtCategory
            clipArtCategoryId={openedClipArtCategoryId}
          />
        </div>
      </div>
    </PageContent>
  );
};

export default ClipArtCategoryList;
