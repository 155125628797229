import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputCheckbox from 'src/component/Input/InputCheckbox';
import InputHidden from 'src/component/Input/InputHidden';
import InputLabel from 'src/component/Input/InputLabel';
import InputNumber from 'src/component/Input/InputNumber';
import InputSelect, { SelectOption } from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import categoryTypeSelectOptions from 'src/util/selectOptions/categoryTypeSelectOptions';
import designTagTypeSelectOptions from 'src/util/selectOptions/designTagTypeSelectOptions';
import {
  Formik, Form, FieldArray,
} from 'formik';
import requiredIntegerValidator from 'src/validator/requiredIntegerValidator';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const CategoryEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const emptyTag: AdminBundle.Entity.API.CategoryControllerV1.CategorySaveV1Request.Tag = { tag: '', typeId: 0 };
  const emptyFilter: AdminBundle.Entity.API.CategoryControllerV1.CategorySaveV1Request.CategoryFilter = {
    filterName: '',
    position: '',
    priority: '',
    type: '',
    values: '',
  };
  const emptyCategory: AdminBundle.Entity.API.CategoryControllerV1.ICategorySaveV1Response = {
    categoryId: undefined,
    categoryTypeId: 0,
    dataCategoryName: '',
    description: '',
    filter: '',
    filterAge: '',
    filterStyle: '',
    filterTheme: '',
    filters: [],
    isBusiness: false,
    isMatchingProductsEnabled: false,
    isAbstractProductsCategory: false,
    isPrivate: true,
    isVisible: true,
    isVisibleInFeed: true,
    menuId: '',
    name: '',
    parentCategoryId: 0,
    priority: 0,
    tags: [],
  };
  const [
    category,
    setCategory,
  ] = useState<AdminBundle.Entity.API.CategoryControllerV1.ICategorySaveV1Request>(emptyCategory);
  const [categorySelectOptionsWithId, setCategorySelectOptionsWithId] = useState<SelectOption[]>([]);
  const [title, setTitle] = useState('Add new category');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ categoryId: string }>();

  const { categoryId } = params;
  const fetchData = async (catId?: string) => {
    setIsLoading(true);
    if (catId) {
      setTitle('Edit category');
      setSubTitle(categoryId);
    }

    try {
      const categoriesResp = await apiRequest(
        ApiEndpoint.categoryV1List,
        {},
        'data.items',
      ) as AdminBundle.Entity.API.CategoryControllerV1.CategoryListV1Response.Category[];
      const optionsWithId = categoriesResp.map(
        (c) => ({
          value: c.categoryId.toString(),
          label: `(${c.categoryId}) ${c.name}`,
        }),
      );

      const rootWithId = {
        value: '0',
        label: '   --- NO PARENT CATEGORY ---',
      };
      setCategorySelectOptionsWithId([rootWithId, ...optionsWithId]);

      if (catId) {
        const singleCategoryResp = await apiRequest(
          ApiEndpoint.categoryV1Get,
          {
            categoryId: catId,
          },
          'data',
        ) as AdminBundle.Entity.API.CategoryControllerV1.ICategoryGetV1Response;

        setSubTitle(singleCategoryResp.name);
        setCategory(singleCategoryResp);
      } else {
        setCategory(emptyCategory);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(categoryId);
  }, [categoryId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.categoryList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={category}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              ApiEndpoint.categoryV1Save,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setCategory(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card header="Main settings" className="mb-3">
              {categoryId ? (
                <InputHidden
                  name="categoryId"
                />
              ) : null}
              <InputText
                name="name"
                label="Category name"
                validate={requiredValidator}
              />
              <InputText
                name="description"
                label="Description"
                validate={requiredValidator}
              />
              <InputSelect
                options={categorySelectOptionsWithId}
                name="parentCategoryId"
                label="Parent category"
              />
              <InputSelect
                options={categoryTypeSelectOptions}
                name="categoryTypeId"
                label="Category type"
              />
              <InputText
                name="menuId"
                label="Menu Id"
                description="Used for grouping categories for left sidebar menu"
              />
              <InputNumber
                name="priority"
                label="Priority"
                validate={requiredIntegerValidator}
              />
              <hr />
              <InputCheckbox
                name="isVisible"
                label="Is enabled"
                disabled
              />
              <InputCheckbox
                name="isVisibleInFeed"
                label="Use in product feed"
              />
              <InputCheckbox
                name="isPrivate"
                label="For regular customers"
              />
              <InputCheckbox
                name="isBusiness"
                label="For business customers"
              />
              <InputCheckbox
                name="isMatchingProductsEnabled"
                label="Matching products enabled"
              />
              <InputCheckbox
                name="isAbstractProductsCategory"
                label="is Abstract Products Category"
              />
            </Card>
            <Card header="Tags" className="mb-3">
              <FieldArray name="tags">
                {({ remove, push }) => (
                  <>
                    <div className="text-right">
                      <div
                        className="btn btn-success"
                        onClick={
                          () => {
                            push(emptyTag);
                          }
                        }
                      >
                        Add tag
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-11">
                        <div className="row">
                          <div className="col-6">
                            <InputLabel text="Tag type" />
                          </div>
                          <div className="col-6">
                            <InputLabel text="Tag" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      values.tags?.map((tag, index) => (
                        <div className="row" key={`tag_${index}`}>
                          <div className="col-11">
                            <div className="row">
                              <div className="col-6">
                                <InputSelect
                                  options={designTagTypeSelectOptions}
                                  name={`tags.${index}.typeId`}
                                />
                              </div>
                              <div className="col-6">
                                <InputText
                                  name={`tags.${index}.tag`}
                                  validate={requiredValidator}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-1">
                            <div
                              className="btn btn-sm btn-danger"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </>
                )}
              </FieldArray>

            </Card>
            <Card header="Filters" className="mb-3">
              <InputText
                name="filter"
                label="Category service filter"
                description="Used in CategoryService for designs filtering"
              />
              <hr />
              <InputText
                name="filterAge"
                label="Filter Age"
                description="Used for displaying filters on category page"
              />
              <InputText
                name="filterStyle"
                label="Filter Style"
                description="Used for displaying filters on category page"
              />
              <InputText
                name="filterTheme"
                label="Filter Theme"
                description="Used for displaying filters on category page"
              />
            </Card>
            <Card header="Category page filters" className="mb-3">
              <FieldArray name="filters">
                {({ remove, push }) => (
                  <>
                    <div className="text-right">
                      <div className="btn btn-success" onClick={() => push(emptyFilter)}>
                        Add filter
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-11">
                        <div className="row">
                          <div className="col-2">
                            <InputLabel text="Filter name" />
                          </div>
                          <div className="col-2">
                            <InputLabel text="Position" />
                          </div>
                          <div className="col-2">
                            <InputLabel text="Priority" />
                          </div>
                          <div className="col-2">
                            <InputLabel text="Type" />
                          </div>
                          <div className="col-2">
                            <InputLabel text="Values" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {values.filters?.map((filter, index) => (
                      <div className="row" key={`filter_${index}`}>
                        <div className="col-11">
                          <div className="row">
                            <div className="col-2">
                              <InputText
                                name={`filters.${index}.filterName`}
                                validate={requiredValidator}
                              />
                            </div>
                            <div className="col-2">
                              <InputText
                                name={`filters.${index}.position`}
                                validate={requiredValidator}
                              />
                            </div>
                            <div className="col-2">
                              <InputNumber
                                name={`filters.${index}.priority`}
                                validate={requiredIntegerValidator}
                              />
                            </div>
                            <div className="col-2">
                              <InputText
                                name={`filters.${index}.type`}
                                validate={requiredValidator}
                              />
                            </div>
                            <div className="col-2">
                              <InputText
                                name={`filters.${index}.values`}
                                validate={requiredValidator}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <div
                            className="btn btn-sm btn-danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default CategoryEdit;
