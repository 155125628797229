import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import EnabledDisabledIcons from 'src/component/EnabledDisabledIcons';
import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
  item: AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeListV1Response.ProductType;
}

export interface Props extends OwnProps {

}

const ProductTypeListItem = ({ item }: Props) => (
  <tr>
    <td>{item.productTypeId}</td>
    <td>
      <EnabledDisabledIcons isEnabled={item.isVisible} />
    </td>
    <td>
      <div>{item.name}</div>
      <div className="small">{item.description}</div>
    </td>
    <td>{item.defaultQuantity}</td>
    <td><EnabledDisabledIcons isEnabled={item.isPrivate} /></td>
    <td>
      <div className="btn-group" role="group">
        <Link
          to={getUrl(RouteName.productTypeEdit, { productTypeId: item.productTypeId })}
          className="btn btn-sm btn-warning"
        >
          Edit
        </Link>
      </div>
    </td>
  </tr>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ProductTypeListItem);
