export default [
  {
    value: '1',
    label: 'Default',
  },
  {
    value: '2',
    label: 'Popular',
  },
  {
    value: '3',
    label: 'Extending',
  },
  {
    value: '4',
    label: 'Landing',
  },
  {
    value: '5',
    label: 'Filter',
  },
];
