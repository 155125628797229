import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputSelect, { SelectOption } from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import {
  Formik, Form,
} from 'formik';
import requiredValidator from 'src/validator/requiredValidator';

type ClipArtCategory = AdminBundle.Entity.API.ClipArtCategoryControllerV1.ClipArtCategoryListV1Response.ClipArtCategory;
type ClipArtCategoryFlat = ClipArtCategory & {
  level: number;
}

type ClipArtCategoryAddRequest = AdminBundle.Entity.API.ClipArtCategoryControllerV1.ClipArtCategoryAddV1Request;

interface RouteUrlParams {
  parentClipArtCategoryId: string;
  sortOrder: string;
  categoryId: string;
}

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const CategoryEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const emptyCategory: ClipArtCategoryAddRequest = {
    sortOrder: 0,
    clipArtId: 0,
    title: '',
    parentClipArtCategoryId: 0,
  };
  const [
    category,
    setCategory,
  ] = useState<Partial<ClipArtCategoryAddRequest>>(emptyCategory);
  const [categorySelectOptionsWithId, setCategorySelectOptionsWithId] = useState<SelectOption[]>([]);
  const [title, setTitle] = useState('Add new category');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<RouteUrlParams>();

  const { categoryId } = params;

  const fetchData = async (catId?: string) => {
    setIsLoading(true);
    if (catId) {
      setTitle('Edit category');
      setSubTitle(categoryId);
    }

    const catgoryToOption = (clipArtCategory: ClipArtCategoryFlat) => (
      {
        value: String(clipArtCategory.clipArtCategoryId),
        label: '••'.repeat(clipArtCategory.level) + clipArtCategory.title,
      } as SelectOption
    );

    const flatClipArtCategories = (categories: ClipArtCategory[], level = 0) => {
      const flatList: ClipArtCategoryFlat[] = [];
      categories.forEach((clipArtCategory: ClipArtCategory) => {
        flatList.push({ ...clipArtCategory, level, subCategories: [] });
        if (clipArtCategory.subCategories.length) {
          flatList.push(
            ...flatClipArtCategories(clipArtCategory.subCategories as ClipArtCategory[], level + 1),
          );
        }
      });
      return flatList;
    };

    // const findCategoryById = (id: number, categories: ClipArtCategory[]) => {
    //   categories.forEach(cat => cat)
    // }

    try {
      const categoriesResp = await apiRequest(
        ApiEndpoint.clipArtCategoryV1List,
        {},
        'data.list',
        'POST',
      ) as ClipArtCategory[];
      const flatClipArtCategoriesList = flatClipArtCategories(categoriesResp);
      const categoryList: SelectOption[] = flatClipArtCategoriesList.map((flatClipArtCategory) => catgoryToOption(flatClipArtCategory));
      const optionsWithId = categoryList.map(
        (c) => ({
          value: c.value,
          label: `${c.label} (${c.value})`,
        }),
      );

      const rootWithId = {
        value: '0',
        label: '   --- NO PARENT CATEGORY ---',
      };
      setCategorySelectOptionsWithId([rootWithId, ...optionsWithId]);

      if (catId) {
        const singleCategoryResp = flatClipArtCategoriesList.find((cat) => cat.clipArtCategoryId === Number(catId));
        if (singleCategoryResp) {
          setSubTitle(singleCategoryResp.title);
          setCategory(singleCategoryResp);
        }
      } else {
        setCategory({
          ...emptyCategory,
          parentClipArtCategoryId: Number(params.parentClipArtCategoryId),
          sortOrder: Number(params.sortOrder),
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(categoryId);
  }, [categoryId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.clipArtCategoryList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={category}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              categoryId ? ApiEndpoint.clipArtCategoryV1Edit : ApiEndpoint.clipArtCategoryV1Add,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setCategory(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card header="Clip art category" className="mb-3">
              <InputText
                name="title"
                label="Category title"
                validate={requiredValidator}
              />
              <InputText
                name="clipArtId"
                label="clipArtId provide when need to be changed"
              />
              <InputSelect
                options={categorySelectOptionsWithId}
                name="parentClipArtCategoryId"
                label="Parent category"
                disabled={Boolean(categoryId)}
              />
              <InputText
                name="sortOrder"
                label="sortOrder"
              />
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default CategoryEdit;
