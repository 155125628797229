import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import EnabledDisabledIcons from 'src/component/EnabledDisabledIcons';
// import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
  category: AdminBundle.Entity.API.CategoryControllerV1.CategoryListV1Response.ICategory;
}

export interface Props extends OwnProps {

}

const CategoryListItem = ({ category }: Props) => (
  <tr>
    <td>
      {category.categoryId}
    </td>
    <td>
      {category.parentCategoryId}
    </td>
    <td>
      <div>
        {category.name}
      </div>
      <div>
        <small>{category.description}</small>
      </div>
    </td>
    <td>
      <div>
        <EnabledDisabledIcons isEnabled={category.isVisible} label="On page" />
      </div>
      <div>
        <EnabledDisabledIcons isEnabled={category.isVisibleInFeed} label="In Feeds" />
      </div>
    </td>
    <td>
      <div>
        <EnabledDisabledIcons isEnabled={category.isPrivate} label="Private" />
      </div>
      <div>
        <EnabledDisabledIcons isEnabled={category.isBusiness} label="Business" />
      </div>
    </td>
    <td>
      <div className="btn-group" role="group">
        {/* <Link */}
        {/*   to={getUrl(RouteName.categoryEdit, { categoryId: category.categoryId })} */}
        {/*   className="btn btn-sm btn-warning" */}
        {/* > */}
        {/*   Edit */}
        {/* </Link> */}
      </div>
    </td>
  </tr>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(CategoryListItem);
