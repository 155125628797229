import React, { FormEvent } from 'react';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import { cloneDeep } from 'lodash';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import styles from './index.module.scss';

type ProductAttributes = AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.ProductAttributes;

export interface OwnProps {
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
  setGlobalLoading: (value: boolean) => void;
  onSuccessfulSave: () => void;
  productAttributes: ProductAttributes[];
  isDuplicateMode: boolean;
}

export interface Props extends OwnProps {

}

enum GroupNameEnum {
  'colorTypeAttributes' = 'colorTypeAttributes',
  'paperFormatAttributes' = 'paperFormatAttributes',
  'paperTypeAttributes' = 'paperTypeAttributes',
  'none' = 'none',
}

enum RuleName {
  'byName' = 'byName',
  'byValue' = 'byValue'
}

const AbstractProductEditStep6 = (
  {
    editedItem,
    onSubmit,
    setStep,
    setGlobalLoading,
    onSuccessfulSave,
    productAttributes,
    isDuplicateMode,
  }: Props,
) => {
  const disableEdit = (editedItem as unknown as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductEditV1Request).abstractProductId !== undefined
    || isDuplicateMode;
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setGlobalLoading(true);
    event.preventDefault();

    // Set value for not edited fields
    productAttributes.forEach((attr) => {
      let added = false;
      if (editedItem.attributesMapping.mappedByName?.indexOf(attr.productAttributeUid) !== -1) {
        added = true;
      }
      if (editedItem.attributesMapping.mappedByValue?.indexOf(attr.productAttributeUid) !== -1) {
        added = true;
      }

      if (!added) {
        editedItem.attributesMapping.mappedByName?.push(attr.productAttributeUid);
      }
    });

    try {
      if ((editedItem as unknown as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductEditV1Request).abstractProductId) {
        await apiRequest(
          ApiEndpoint.abstractProductV1Edit,
          editedItem,
          'data',
          'POST',
        );
      } else {
        await apiRequest(
          ApiEndpoint.abstractProductV1Add,
          editedItem,
          'data',
          'POST',
        );
      }
      onSuccessfulSave();
    } catch (e) {
      setGlobalLoading(false);
    }
  };

  const getSelectedAttributeMappingBulletName = (attributeUid: string) => {
    if (editedItem.attributesMapping.colorTypeAttributes?.indexOf(attributeUid) !== -1) {
      return GroupNameEnum.colorTypeAttributes;
    }

    if (editedItem.attributesMapping.paperFormatAttributes?.indexOf(attributeUid) !== -1) {
      return GroupNameEnum.paperFormatAttributes;
    }

    if (editedItem.attributesMapping.paperTypeAttributes?.indexOf(attributeUid) !== -1) {
      return GroupNameEnum.paperTypeAttributes;
    }

    return GroupNameEnum.none;
  };

  const getSelectedAttributeMappingRuleBulletName = (attributeUid: string) => {
    if (editedItem.attributesMapping.mappedByName?.indexOf(attributeUid) !== -1) {
      return RuleName.byName;
    }
    if (editedItem.attributesMapping.mappedByValue?.indexOf(attributeUid) !== -1) {
      return RuleName.byValue;
    }

    return RuleName.byName;
  };

  const onAttributeMappingBulletClick = (groupName: GroupNameEnum, attributeUid: string) => {
    const clone = cloneDeep(editedItem);
    const mapping = clone.attributesMapping;
    const paperTypeAttributes = [...mapping.paperTypeAttributes];
    const colorTypeAttributes = [...mapping.colorTypeAttributes];
    const paperFormatAttributes = [...mapping.paperFormatAttributes];

    const index1 = paperTypeAttributes?.indexOf(attributeUid);
    if (index1 !== -1) {
      paperTypeAttributes.splice(index1, 1);
    }
    const index2 = colorTypeAttributes?.indexOf(attributeUid);
    if (index2 !== -1) {
      colorTypeAttributes.splice(index2, 1);
    }
    const index3 = paperFormatAttributes?.indexOf(attributeUid);
    if (index3 !== -1) {
      paperFormatAttributes.splice(index3, 1);
    }

    if (groupName === GroupNameEnum.colorTypeAttributes) {
      colorTypeAttributes.push(attributeUid);
    } else if (groupName === GroupNameEnum.paperFormatAttributes) {
      paperFormatAttributes?.push(attributeUid);
    } else if (groupName === GroupNameEnum.paperTypeAttributes) {
      paperTypeAttributes.push(attributeUid);
    }
    clone.attributesMapping.paperTypeAttributes = paperTypeAttributes;
    clone.attributesMapping.colorTypeAttributes = colorTypeAttributes;
    clone.attributesMapping.paperFormatAttributes = paperFormatAttributes;
    onSubmit(clone);
  };

  const onAttributeMappingRuleBulletClick = (ruleName: RuleName, attributeUid: string) => {
    const clone = cloneDeep(editedItem);
    const mappingRules = clone.attributesMapping;
    const byName = [...mappingRules.mappedByName];
    const byValue = [...mappingRules.mappedByValue];

    const index1 = byName?.indexOf(attributeUid);
    if (index1 !== -1) {
      byName.splice(index1, 1);
    }
    const index2 = byValue?.indexOf(attributeUid);
    if (index2 !== -1) {
      byValue.splice(index2, 1);
    }

    if (ruleName === RuleName.byName) {
      byName.push(attributeUid);
    } else if (ruleName === RuleName.byValue) {
      byValue?.push(attributeUid);
    }
    clone.attributesMapping.mappedByName = byName;
    clone.attributesMapping.mappedByValue = byValue;
    onSubmit(clone);
  };

  const isAttributeMappingSelected = (bulletName: GroupNameEnum, attributeUid: string) => bulletName === getSelectedAttributeMappingBulletName(attributeUid);
  const isAttributeMappingRuleSelected = (ruleName: RuleName, attributeUid: string) => ruleName === getSelectedAttributeMappingRuleBulletName(attributeUid);

  return (
    <form onSubmit={handleSubmit}>
      <FormConfirmButtonsBar showSubmit onBack={() => setStep(5)} />
      <Card header="Attributes mapping" className="mb-3">
        <table className="table">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Internal type simulation</th>
              <th>Pricing group</th>
            </tr>
          </thead>
          <tbody>
            {productAttributes.map((attr) => (
              <tr key={attr.productAttributeUid} style={attr.values.length > 1 ? { background: 'lightyellow' } : {}}>
                <td>
                  <div>{attr.title ? attr.title : attr.productAttributeUid}</div>
                  <div className="small">
                    {attr.values?.map((a) => (
                      <div key={a.productAttributeValueUid}>{a.productAttributeValueUid}</div>))}
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      className={styles.radio}
                      type="radio"
                      value="none"
                      disabled={disableEdit}
                      name={`${attr.productAttributeUid}_mapping`}
                      checked={isAttributeMappingSelected(GroupNameEnum.none, attr.productAttributeUid)}
                      onChange={() => onAttributeMappingBulletClick(GroupNameEnum.none, attr.productAttributeUid)}
                    />
                    None
                  </div>
                  <div>
                    <input
                      className={styles.radio}
                      type="radio"
                      value="colorType"
                      disabled={disableEdit}
                      name={`${attr.productAttributeUid}_mapping`}
                      checked={isAttributeMappingSelected(GroupNameEnum.colorTypeAttributes, attr.productAttributeUid)}
                      onChange={() => onAttributeMappingBulletClick(GroupNameEnum.colorTypeAttributes, attr.productAttributeUid)}
                    />
                    Color Type
                  </div>
                  <div>
                    <input
                      className={styles.radio}
                      type="radio"
                      value="paperType"
                      disabled={disableEdit}
                      name={`${attr.productAttributeUid}_mapping`}
                      checked={isAttributeMappingSelected(GroupNameEnum.paperTypeAttributes, attr.productAttributeUid)}
                      onChange={() => onAttributeMappingBulletClick(GroupNameEnum.paperTypeAttributes, attr.productAttributeUid)}
                    />
                    Paper Type
                  </div>
                  <div>
                    <input
                      className={styles.radio}
                      type="radio"
                      value="paperFormat"
                      disabled={disableEdit}
                      name={`${attr.productAttributeUid}_mapping`}
                      checked={isAttributeMappingSelected(GroupNameEnum.paperFormatAttributes, attr.productAttributeUid)}
                      onChange={() => onAttributeMappingBulletClick(GroupNameEnum.paperFormatAttributes, attr.productAttributeUid)}
                    />
                    Paper Format
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      className={styles.radio}
                      type="radio"
                      value="name"
                      disabled={disableEdit}
                      name={`${attr.productAttributeUid}_mapping_rule`}
                      checked={isAttributeMappingRuleSelected(RuleName.byName, attr.productAttributeUid)}
                      onChange={() => onAttributeMappingRuleBulletClick(RuleName.byName, attr.productAttributeUid)}
                    />
                    Attribute Name
                  </div>
                  <div>
                    <input
                      className={styles.radio}
                      type="radio"
                      value="value"
                      disabled={disableEdit}
                      name={`${attr.productAttributeUid}_mapping_rule`}
                      checked={isAttributeMappingRuleSelected(RuleName.byValue, attr.productAttributeUid)}
                      onChange={() => onAttributeMappingRuleBulletClick(RuleName.byValue, attr.productAttributeUid)}
                    />
                    Attribute Value
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      <FormConfirmButtonsBar showSubmit onBack={() => setStep(5)} />
    </form>
  );
};

export default AbstractProductEditStep6;
