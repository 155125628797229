import { combineReducers } from 'redux';
import app, { appInitialState } from 'src/store/app/slice';
import { AppState } from 'src/store/app/types';
import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

export interface Store {
  app: AppState;
}

export const rootReducer = combineReducers({
  app,

});

export type RootState = ReturnType<typeof rootReducer>;

export const initState: Store = {
  app: appInitialState,
};

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store;
