import React from 'react';
import { connect } from 'react-redux';

export interface OwnProps {
  onSave?: () => void;
  onCancel?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  onCustomButton?: () => void;
  customButtonTitle?: string;
  showSubmit?: boolean;
  submitLabel?: string;
}

export interface Props extends OwnProps {

}

const FormConfirmButtonsBar = (
  {
    onBack,
    onCancel,
    onSave,
    onNext,
    onCustomButton,
    customButtonTitle,
    showSubmit,
    submitLabel,
  }: Props,
) => (
  <div className="row mb-3">
    <div className="col-6 text-left">
      {onBack ? (<button type="button" className="btn btn-secondary mr-2" onClick={onBack}>Back</button>) : null}
    </div>
    <div className="col-6 text-right">
      {onCancel ? (<button type="button" className="btn btn-danger ml-2" onClick={onCancel}>Cancel</button>) : null}
      {onSave ? (<button type="button" className="btn btn-success ml-2" onClick={onSave}>Save</button>) : null}
      {onNext ? (<button type="button" className="btn btn-success ml-2" onClick={onNext}>Next</button>) : null}
      {onCustomButton ? (<button type="button" className="btn btn-success ml-2" onClick={onCustomButton}>{customButtonTitle}</button>) : null}
      {showSubmit ? (<button type="submit" className="btn btn-success ml-2">{submitLabel ?? 'Save'}</button>) : null}
    </div>
  </div>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(FormConfirmButtonsBar);
