import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import AttributeListItem from 'src/view/AttributeList/AttributeListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

export interface AttributeListListItem {
  attributeId: number;
  attributeTypeId: number;
  attributeTypeName: string;
  value: string;
  gApiName?: string;
}

const AttributeList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [items, setItems] = useState(
    [] as AttributeListListItem[],
  );

  const fetchData = async () => {
    try {
      const attributeTypesList = await apiRequest(
        ApiEndpoint.attributeTypeV1List,
        {},
        'data.list',
      ) as AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeListV1Response.AttributeType[];

      const attributesList = await apiRequest(
        ApiEndpoint.attributeV1List,
        {},
        'data.list',
      ) as AdminBundle.Entity.API.AttributeControllerV1.AttributeListV1Response.Attribute[];

      const res: AttributeListListItem[] = attributesList.map((attribute) => {
        const type = attributeTypesList.find((t) => t.attributeTypeId === attribute.attributeTypeId);
        let gApiName = 'NOT-FOUND';
        let name = 'NOT-FOUND';
        if (type) {
          name = type.name;
          gApiName = type.gApiName;
        }
        return {
          ...attribute,
          attributeTypeName: name,
          gApiName,
        };
      });

      setItems(res);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const filteredItems = search
    ? items.filter((item) => (
      (item.attributeTypeName || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (item.value || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (item.gApiName || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    ))
    : items;

  return (
    <PageContent title="Product attributes list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.attributeAdd)}>Add new</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Value</th>
            <th scope="col">Attribute type</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((r) => (<AttributeListItem item={r} key={r.attributeId} />))}
        </tbody>
      </table>
    </PageContent>
  );
};

export default AttributeList;
