import { FileUploader } from 'file-uploader';
import classNames from 'classnames';
import React, { MutableRefObject } from 'react';
import ApiFileUploader from 'src/component/ApiFileUploader';
import { connect } from 'react-redux';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import SidebarMenu from 'src/component/SidebarMenu';
import routing, { getUrl, RouteName } from 'src/routing';
import styles from 'src/component/MainContentArea/index.module.scss';

export interface OwnProps {
}

export interface Props extends OwnProps {
  uploaderRef: MutableRefObject<FileUploader | undefined>;
}

const MainContentArea = ({ uploaderRef }: Props) => (
  <div
    className={classNames({
      row: true,
      [styles.height100]: true,
    })}
  >
    <SidebarMenu />
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
      <Switch>
        {routing.map((routingItem) => (
          <Route
            path={routingItem.path}
            component={routingItem.component}
            exact={routingItem.exact}
            key={routingItem.name}
          />
        ))}
        <Route path="*">
          <Redirect to={getUrl(RouteName.pageNotFound)} />
        </Route>
      </Switch>
      <ApiFileUploader uploaderRef={uploaderRef} />
    </main>
  </div>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(MainContentArea);
