import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { getRouteByName, getUrl, RouteName } from 'src/routing';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from 'src/component/SidebarMenu/SidebarLink/index.module.scss';

export interface OwnProps {
  routeName: RouteName;
  text: string;
}

export interface Props extends OwnProps {

}

const SidebarLink = ({ routeName, text }: Props) => {
  const route = getRouteByName(routeName);
  const match = useRouteMatch(route);

  const className = classNames({
    'nav-link': true,
    [styles.navLink]: true,
    [styles.active]: match,
  });

  return (
    <Link className={className} to={getUrl(routeName)}>
      {text}
    </Link>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(SidebarLink);
