import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import EnabledDisabledIcons from 'src/component/EnabledDisabledIcons';
import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
  urlMapping: AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingListV1Response.UrlMapping;
}

export interface Props extends OwnProps {

}

const UrlMappingListItem = ({ urlMapping }: Props) => (
  <tr>
    <td>
      <EnabledDisabledIcons isEnabled={urlMapping.isEnabled} />
    </td>
    <td>{urlMapping.url}</td>
    <td>{urlMapping.service}</td>
    <td>
      <div className="btn-group" role="group">
        <Link
          to={getUrl(RouteName.urlMappingEdit, { urlMappingId: urlMapping.urlMappingId })}
          className="btn btn-sm btn-warning"
        >
          Edit
        </Link>
      </div>
    </td>
  </tr>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(UrlMappingListItem);
