import * as React from 'react';
import AbstractProductBundles from 'src/view/AbstractProductBundles';
import AbstractProductEdit from 'src/view/AbstractProductEdit';
import AbstractProductList from 'src/view/AbstractProductList';
import AbstractProductTranslationPattern from 'src/view/AbstractProductTranslationPattern';
import AbstractProductCountryAvailability from 'src/view/AbstractProductCountryAvailability';
import AttributeEdit from 'src/view/AttributeEdit';
import AttributeList from 'src/view/AttributeList';
import AttributeTypeEdit from 'src/view/AttributeTypeEdit';
import AttributeTypeList from 'src/view/AttributeTypeList';
import CategoryEdit from 'src/view/CategoryEdit';
import CategoryList from 'src/view/CategoryList';
import ClipArtCategoryEdit from 'src/view/ClipArtCategoryEdit';
import ClipArtCategoryList from 'src/view/ClipArtCategoryList';
import ClipArtPriceTierList from 'src/view/ClipArtPriceTierList';
import ClipArtPriceTierEdit from 'src/view/ClipArtPriceTierEdit';
import ClipArtEdit from 'src/view/ClipArtEdit';
import ClipArtImport from 'src/view/ClipArtImport';
import Dashboard from 'src/view/Dashboard';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps, generatePath } from 'react-router';
import LandingPageCategoryEdit from 'src/view/LandingPageCategoryEdit';
import LandingPageCategoryList from 'src/view/LandingPageCategoryList';
import PageNotFound from 'src/view/PageNotFound';
import ProductTypeEdit from 'src/view/ProductTypeEdit';
import ProductTypeList from 'src/view/ProductTypeList';
import UrlMappingEdit from 'src/view/UrlMappingEdit';
import UrlMappingList from 'src/view/UrlMappingList';
import ClipArtUploadReport from 'src/view/ClipArtUploadReport';

export interface RoutingItem {
  name: string;
  path: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact: boolean;
}

const pathPrefix = '/admin/new';

export enum RouteName {
  'dashboard' = 'dashboard',
  'pageNotFound' = 'pageNotFound',
  // Url mapping
  'urlMappingList' = 'urlMappingList',
  'urlMappingEdit' = 'urlMappingEdit',
  'urlMappingAdd' = 'urlMappingAdd',
  // Categories
  'categoryList' = 'categoryList',
  'categoryEdit' = 'categoryEdit',
  'categoryAdd' = 'categoryAdd',
  // Landing page categories
  'landingPageCategoryList' = 'landingPageCategoryList',
  'landingPageCategoryEdit' = 'landingPageCategoryEdit',
  'landingPageCategoryAdd' = 'landingPageCategoryAdd',
  // Product type
  'productTypeList' = 'productTypeList',
  'productTypeEdit' = 'productTypeEdit',
  'productTypeAdd' = 'productTypeAdd',
  // Attribute type
  'attributeTypeList' = 'attributeTypeList',
  'attributeTypeEdit' = 'attributeTypeEdit',
  'attributeTypeAdd' = 'attributeTypeAdd',
  // Attribute
  'attributeList' = 'attributeList',
  'attributeEdit' = 'attributeEdit',
  'attributeAdd' = 'attributeAdd',
  // Abstract product
  'abstractProductList' = 'abstractProductList',
  'abstractProductEdit' = 'abstractProductEdit',
  'abstractProductDuplicate' = 'abstractProductDuplicate',
  'abstractProductAdd' = 'abstractProductAdd',
  // Clipart categories
  'clipArtCategoryList' = 'clipArtCategoryList',
  'clipArtCategoryAdd' = 'clipArtCategoryAdd',
  'clipArtCategoryEdit' = 'clipArtCategoryEdit',
  // Clipart
  'clipArtAdd' = 'clipArtAdd',
  'clipArtEdit' = 'clipArtEdit',
  // Clipart
  'clipArtImport' = 'clipArtImport',
  'clipArtReport' = 'clipArtReport',
  // Clipart price tier
  'clipArtPriceTierAdd' = 'clipArtPriceTierAdd',
  'clipArtPriceTierList' = 'clipArtPriceTierList',
  // Translation pattern
  'abstractProductTranslationPattern' = 'abstractProductTranslationPattern',
  'abstractProductCountryAvailability' = 'abstractProductCountryAvailability',
  'abstractProductBundles' = 'abstractProductBundles',
}

const routing: RoutingItem[] = [
  {
    name: RouteName.attributeAdd,
    path: `${pathPrefix}/attribute/add`,
    component: AttributeEdit,
    exact: true,
  },
  {
    name: RouteName.attributeEdit,
    path: `${pathPrefix}/attribute/edit/:attributeId`,
    component: AttributeEdit,
    exact: true,
  },
  {
    name: RouteName.attributeList,
    path: `${pathPrefix}/attribute/list`,
    component: AttributeList,
    exact: true,
  },
  {
    name: RouteName.abstractProductAdd,
    path: `${pathPrefix}/abstract-product/add`,
    component: AbstractProductEdit,
    exact: true,
  },
  {
    name: RouteName.abstractProductEdit,
    path: `${pathPrefix}/abstract-product/edit/:abstractProductId`,
    component: AbstractProductEdit,
    exact: true,
  },
  {
    name: RouteName.abstractProductDuplicate,
    path: `${pathPrefix}/abstract-product/duplicate/:abstractProductId`,
    component: AbstractProductEdit,
    exact: true,
  },
  {
    name: RouteName.abstractProductList,
    path: `${pathPrefix}/abstract-product/list`,
    component: AbstractProductList,
    exact: true,
  },
  {
    name: RouteName.abstractProductTranslationPattern,
    path: `${pathPrefix}/abstract-product/translation-pattern/:abstractProductId`,
    component: AbstractProductTranslationPattern,
    exact: true,
  },
  {
    name: RouteName.abstractProductBundles,
    path: `${pathPrefix}/abstract-product/bundles/:abstractProductId`,
    component: AbstractProductBundles,
    exact: true,
  },
  {
    name: RouteName.abstractProductCountryAvailability,
    path: `${pathPrefix}/abstract-product/country-availability/:abstractProductId`,
    component: AbstractProductCountryAvailability,
    exact: true,
  },
  {
    name: RouteName.attributeTypeAdd,
    path: `${pathPrefix}/attribute-type/add`,
    component: AttributeTypeEdit,
    exact: true,
  },
  {
    name: RouteName.attributeTypeEdit,
    path: `${pathPrefix}/attribute-type/edit/:attributeTypeId`,
    component: AttributeTypeEdit,
    exact: true,
  },
  {
    name: RouteName.attributeTypeList,
    path: `${pathPrefix}/attribute-type/list`,
    component: AttributeTypeList,
    exact: true,
  },
  {
    name: RouteName.landingPageCategoryAdd,
    path: `${pathPrefix}/landing-page-category/add`,
    component: LandingPageCategoryEdit,
    exact: true,
  },
  {
    name: RouteName.landingPageCategoryEdit,
    path: `${pathPrefix}/landing-page-category/edit/:landingPageCategoryId`,
    component: LandingPageCategoryEdit,
    exact: true,
  },
  {
    name: RouteName.landingPageCategoryList,
    path: `${pathPrefix}/landing-page-category/list`,
    component: LandingPageCategoryList,
    exact: true,
  },
  {
    name: RouteName.urlMappingAdd,
    path: `${pathPrefix}/url-mapping/add`,
    component: UrlMappingEdit,
    exact: true,
  },
  {
    name: RouteName.urlMappingEdit,
    path: `${pathPrefix}/url-mapping/edit/:urlMappingId`,
    component: UrlMappingEdit,
    exact: true,
  },
  {
    name: RouteName.urlMappingList,
    path: `${pathPrefix}/url-mapping/list`,
    component: UrlMappingList,
    exact: true,
  },
  {
    name: RouteName.categoryList,
    path: `${pathPrefix}/category/list`,
    component: CategoryList,
    exact: true,
  },
  {
    name: RouteName.categoryAdd,
    path: `${pathPrefix}/category/add`,
    component: CategoryEdit,
    exact: true,
  },
  {
    name: RouteName.categoryEdit,
    path: `${pathPrefix}/category/edit/:categoryId`,
    component: CategoryEdit,
    exact: true,
  },
  {
    name: RouteName.clipArtCategoryList,
    path: `${pathPrefix}/clipart-category/list`,
    component: ClipArtCategoryList,
    exact: true,
  },
  {
    name: RouteName.clipArtAdd,
    path: `${pathPrefix}/clipart-category/list/:categoryId/addClipArt`,
    component: ClipArtEdit,
    exact: true,
  },
  {
    name: RouteName.clipArtEdit,
    path: `${pathPrefix}/clipart-category/list/:categoryId/edit/:clipArtId`,
    component: ClipArtEdit,
    exact: true,
  },
  {
    name: RouteName.clipArtImport,
    path: `${pathPrefix}/clipart-import/`,
    component: ClipArtImport,
    exact: true,
  },
  {
    name: RouteName.clipArtReport,
    path: `${pathPrefix}/clipart-report/`,
    component: ClipArtUploadReport,
    exact: true,
  },
  {
    name: RouteName.clipArtCategoryAdd,
    path: `${pathPrefix}/clipart-category/add/:parentClipArtCategoryId/:sortOrder`,
    component: ClipArtCategoryEdit,
    exact: true,
  },
  {
    name: RouteName.clipArtCategoryEdit,
    path: `${pathPrefix}/clipart-category/edit/:categoryId`,
    component: ClipArtCategoryEdit,
    exact: true,
  },
  {
    name: RouteName.clipArtPriceTierList,
    path: `${pathPrefix}/clipart-price-tier/list`,
    component: ClipArtPriceTierList,
    exact: true,
  },
  {
    name: RouteName.clipArtPriceTierAdd,
    path: `${pathPrefix}/clipart-price-tier/add`,
    component: ClipArtPriceTierEdit,
    exact: true,
  },
  {
    name: RouteName.productTypeList,
    path: `${pathPrefix}/product-type/list`,
    component: ProductTypeList,
    exact: true,
  },
  {
    name: RouteName.productTypeAdd,
    path: `${pathPrefix}/product-type/add`,
    component: ProductTypeEdit,
    exact: true,
  },
  {
    name: RouteName.productTypeEdit,
    path: `${pathPrefix}/product-type/edit/:productTypeId`,
    component: ProductTypeEdit,
    exact: true,
  },
  {
    name: RouteName.pageNotFound,
    path: `${pathPrefix}/404`,
    component: PageNotFound,
    exact: true,
  },
  {
    name: RouteName.dashboard,
    path: `${pathPrefix}/`,
    component: Dashboard,
    exact: true,
  },
];

export default routing;

export const getRouteByName = (name: RouteName) => {
  const route = routing.find((r) => r.name === name);

  if (!route) {
    throw new Error(`Route ${name} is not defined!`);
  }
  return route;
};

export const getUrl = (name: RouteName, params: { [parameterId: string]: any } = {}) => {
  const route = getRouteByName(name);
  return generatePath(route.path, params);
};
