import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputHidden from 'src/component/Input/InputHidden';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import { LocaleEnum } from 'src/types.d';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const UrlMappingEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  let { locales } = useIntegrationLayer();
  locales = locales.filter((locale) => locale !== LocaleEnum.enXX);
  const emptyUrlLocales: AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingSaveV1Request.IUrlMappingLocaleSave[] = [];

  locales.forEach((locale) => {
    const urlLocale: AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingSaveV1Request.IUrlMappingLocaleSave = {
      locale,
      url: '',
    };
    emptyUrlLocales.push(urlLocale);
  });

  const emptyUrlMapping: AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingSaveV1Request.IUrlMappingSave = {
    isEnabled: true, locales: emptyUrlLocales, service: '', url: '',
  };
  const [
    urlMappingSaveItem,
    setUrlMappingSaveItem,
  ] = useState<AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingSaveV1Request.IUrlMappingSave>(emptyUrlMapping);
  const [title, setTitle] = useState('Add new url mapping');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ urlMappingId: string }>();

  const { urlMappingId } = params;
  const fetchData = async (mappingId?: string) => {
    setIsLoading(true);
    if (urlMappingId) {
      setTitle('Edit url mapping');
      setSubTitle(urlMappingId);
      const response = await apiRequest(
        ApiEndpoint.urlMappingV1Get,
        {
          urlMappingId: mappingId,
        },
        'data',
      ) as AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingGetV1Response;

      setSubTitle(response.service);

      locales.forEach((locale) => {
        const objectForLocale = response.locales.find((loc) => loc.locale === locale);
        if (!objectForLocale) {
          const newobj = {
            locale,
            mappingId,
            url: '',
          };
          response.locales.push(newobj as any as AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingGetV1Response.UrlMappingLocale);
        }
      });
      setUrlMappingSaveItem(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(urlMappingId);
  }, [urlMappingId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.urlMappingList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={urlMappingSaveItem}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              ApiEndpoint.urlMappingV1Save,
              {
                items: [values],
                invalidateCache: true,
              },
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setUrlMappingSaveItem(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card header="Main settings" className="mb-3">
              {values.urlMappingId ? (
                <InputHidden name="urlMappingId" />
              ) : null}
              <InputHidden name="isEnabled" />
              <InputText
                name="url"
                label="Default url"
                validate={requiredValidator}
              />
              <InputText
                name="service"
                label="Service"
                validate={requiredValidator}
              />
            </Card>
            <Card header="Remaining locales" className="mb-3">
              {values.locales?.map((urlLocale, index) => (
                <div key={`wrapper_${urlLocale.locale}`}>
                  <InputText
                    name={`locales.${index}.url`}
                    label={urlLocale.locale}
                  />
                  <InputHidden
                    name={`locales.${index}.locale`}
                  />
                  {urlLocale.urlMappingLocaleId ? (
                    <InputHidden
                      name={`locales.${index}.urlMappingLocaleId`}
                    />
                  ) : null}
                  <InputHidden
                    name={`locales.${index}.urlMappingId`}
                  />
                </div>
              ))}
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default UrlMappingEdit;
