export default [
  { value: 'activity_poster', label: 'Activity poster' },
  { value: 'photobook_layflat', label: 'Photobook layflat' },
  { value: 'posters', label: 'Posters' },
  { value: 'mugs', label: 'Mugs' },
  { value: 'cards', label: 'Cards' },
  { value: 'educational_book', label: 'Educational book' },
  { value: 'acrylic_print', label: 'Acrylic print' },
  { value: 'calendars', label: 'Calendars' },
  { value: 'artistic_posters', label: 'Artistic posters' },
  { value: 'notebook', label: 'Notebook' },
  { value: 'photo_books', label: 'Photo books' },
  { value: 'frame_or_hanger', label: 'Frame or hanger' },
  { value: 'metallic_print', label: 'Metallic print' },
  { value: 'birth_poster', label: 'Birth poster' },
  { value: 'apparel', label: 'Apparel' },
  { value: 'canvas', label: 'Canvas' },
];
