import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
// import apiRequest from 'src/api/apiRequest';
// import ApiEndpoint from 'src/api/endpoints';
// import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import PageContent from 'src/component/PageContent';
// import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ClipArtImport = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState('');
  const [importData, setImportData] = useState<string[][]>([]);
  const [validationErrors, setValidationErrors] = useState<number[]>([]);
  const [cellForEditIndex, setCellForEditIndex] = useState(-1);

  const fetchData = async () => {
    try {
      // const response = await apiRequest(
      //   ApiEndpoint.clipArtPriceTierV1List,
      //   {},
      //   'data.data',
      //   'POST',
      // ) as AdminBundle.Entity.API.ClipArtPriceTierControllerV1.ClipArtPriceTierListV1Response.PriceTier[];
      // setCategories(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  // const getPriceSheet = async () => {
  //   const response = await apiRequest(
  //     ApiEndpoint.clipArtPriceTierV1GetPriceSheet,
  //     {},
  //     'data',
  //     'GET',
  //   ) as Blob;
  //   const translationsData = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //   const blobUrl = window.URL.createObjectURL(translationsData);
  //   window.location.href = blobUrl;
  // };

  const importFromLink = () => {
    setImportData([
      // eslint-disable-next-line max-len
      ['col 1', 'col 2', 'col 3', 'col 1', 'col 2', 'col 3', 'col 1', 'col 2', 'col 3', 'col 1', 'col 2', 'col 3', 'col 1', 'col 2', 'col 3', 'col 1', 'col 2', 'col 3', 'col 1', 'col 2', 'col 3'],
      // eslint-disable-next-line max-len
      ['vol 1', 'vol 2', 'vol 3', 'vol 1', 'vol 2', 'vol 3', 'vol 1', 'vol 2', 'vol 3', 'vol 1', 'vol 2', 'vol 3', 'vol 1', 'vol 2', 'vol 3', 'vol 1', 'vol 2', 'vol 3', 'vol 1', 'vol 2', 'vol 3'],
      // eslint-disable-next-line max-len
      ['vol 11', 'vol 12', 'vol 13', 'vol 11', 'vol 12', 'vol 13', 'vol 11', 'vol 12', 'vol 13', 'vol 11', 'vol 12', 'vol 13', 'vol 11', 'vol 12', 'vol 13', 'vol 11', 'vol 12', 'vol 13', 'vol 11', 'vol 12', 'vol 13'],
    ]);
    setValidationErrors([1 * 1000 + 2]);
  };

  const resetImport = () => {
    setImportData([]);
    setValidationErrors([]);
    setCellForEditIndex(-1);
  };

  const editCell = (rowIndex: number, cellIndex: number) => {
    setCellForEditIndex(rowIndex * 1000 + cellIndex);
  };

  const lostFocus = () => {
    setCellForEditIndex(-1);
  };

  const updateCell = (rowIndex: number, cellIndex: number, data: string) => {
    const updatedData = cloneDeep(importData);
    updatedData[rowIndex][cellIndex] = data;
    setImportData(updatedData);
  };

  const sendImortData = () => {

  };

  return (
    <PageContent title="Clip art price tier list" isLoading={isLoading}>
      <div className="row mb-3">
        <div className="col-12 text-left">
          {importData.length === 0 && (
            <>
              <input
                type="text"
                value={selectedFile}
                onChange={(e) => setSelectedFile(e.target.value)}
              />
              &nbsp;
              <button className="btn btn-success" onClick={importFromLink} disabled={!selectedFile}>Import </button>
            </>
          )}
          {importData.length > 0 && <button className="btn btn-success" onClick={resetImport}>Reset import data</button>}
        </div>
      </div>
      {importData.length > 0 && (
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    {importData[0].map((columnName) => <th scope="col">{columnName}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {importData.map((dataRow, rowIndex) => (
                    rowIndex > 0 && (
                      <tr>
                        {dataRow.map((dataCell, cellIndex) => {
                          const invalidClass = validationErrors.indexOf(rowIndex * 1000 + cellIndex) >= 0 ? 'bg-danger text-white' : '';
                          if (cellForEditIndex === rowIndex * 1000 + cellIndex) {
                            return (
                              <td className={invalidClass}>
                                <input
                                  type="text"
                                  value={dataCell}
                                  onBlur={(e) => lostFocus()}
                                  onChange={(e) => updateCell(rowIndex, cellIndex, e.target.value)}
                                />
                              </td>
                            );
                          }
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                          return (<td className={invalidClass} onClick={() => editCell(rowIndex, cellIndex)}>{dataCell}</td>);
                        })}
                      </tr>
                    )
                  ))}
                </tbody>
              </table>
            </div>
            <button className="btn btn-success" onClick={sendImortData}>Proceed import</button>
          </div>
        </div>
      )}
    </PageContent>
  );
};

export default ClipArtImport;
