import { FileUploader } from 'file-uploader';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import MainContentArea from 'src/component/MainContentArea';
import TopBar from 'src/component/TopBar';
import { Store } from 'src/store';
import styles from './App.module.scss';

export interface Props {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const App = ({ dispatch }: Props) => {
  const uploaderRef = useRef<FileUploader>();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <TopBar />
        <div
          className={classNames({
            'container-fluid': true,
            [styles.containerFluid]: true,
          })}
        >
          <MainContentArea uploaderRef={uploaderRef} />
        </div>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(App);
