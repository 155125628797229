import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { SelectOption } from 'src/component/Input/InputSelect';
import PageContent from 'src/component/PageContent';
import { getRouteByName, getUrl, RouteName } from 'src/routing';
import AbstractProductEditStep1 from 'src/view/AbstractProductEdit/AbstractProductEditStep1';
import AbstractProductEditStep2 from 'src/view/AbstractProductEdit/AbstractProductEditStep2';
import AbstractProductEditStep3 from 'src/view/AbstractProductEdit/AbstractProductEditStep3';
import AbstractProductEditStep4 from 'src/view/AbstractProductEdit/AbstractProductEditStep4';
import AbstractProductEditStep5 from 'src/view/AbstractProductEdit/AbstractProductEditStep5';
import AbstractProductEditStep6 from 'src/view/AbstractProductEdit/AbstractProductEditStep6';
import AbstractProductEditStep7 from 'src/view/AbstractProductEdit/AbstractProductEditStep7';

type ProductAttributes = AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.ProductAttributes;
type ProductAttributesValue = AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.ProductAttributesValue;

export interface OwnProps {
}

export interface Props extends OwnProps {

}

export interface MappedAttribute {
  attributeId: number;
  typeName: string;
  value: string;
}

const AbstractProductEdit = (props: Props) => {
  const history = useHistory();
  const route = getRouteByName(RouteName.abstractProductDuplicate);
  const isDuplicateMode = useRouteMatch(route);

  const [isLoading, setIsLoading] = useState(false);

  const attributesMapping: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request.GApiToOPAttributesMappingRule = {
    colorTypeAttributes: [],
    paperFormatAttributes: [],
    paperTypeAttributes: [],
    mappedByName: [],
    mappedByValue: [],
  };

  const emptyItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request = {
    internalAttributesIds: [],
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    categoryId: undefined,
    productPageSelectors: [],
    designProductPageSelectors: [],
    editorSelectors: [],
    attributesMapping,
    gApiProductIds: [],
    attributeFilters: [],
    baseProductUid: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    gApiCatalogId: undefined,
    name: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    productTypeId: undefined,
  };

  const [
    gapiProducts,
    setGapiProducts,
  ] = useState<AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[]>([]);

  const [
    editedItem,
    setEditedItem,
  ] = useState<AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request
    | AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductEditV1Request>(emptyItem);

  const [
    catalogs,
    setCatalogs,
  ] = useState<AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.GApiCatalog[]>([]);

  const [namePlaceholder, setNamePlaceholder] = useState<string | undefined>(undefined);

  const [
    productAttributes,
    setProductAttributes,
  ] = useState<ProductAttributes[]>([]);

  const [mappedAttributesList, setMappedAttributesList] = useState<MappedAttribute[]>([]);
  const [
    catalogsSelectOptions,
    setCatalogsSelectOptions,
  ] = useState<SelectOption[]>([]);

  const [step, setStep] = useState<number>(1);

  const [title, setTitle] = useState('Add new abstract product');
  const [productTypeSelectOptions, setProductTypeSelectOptions] = useState<SelectOption[]>([]);
  const [categorySelectOptions, setCategorySelectOptions] = useState<SelectOption[]>([]);
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ abstractProductId: string | undefined }>();

  const { abstractProductId } = params;
  const fetchData = async (id?: string) => {
    setIsLoading(true);

    const productTypes = await apiRequest(
      ApiEndpoint.productTypeV1List,
      {},
      'data.list',
    ) as AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeListV1Response.ProductType[];

    const productTypeOptions = productTypes.map((pt) => ({
      label: pt.name,
      value: `${pt.productTypeId}`,
    }));
    setProductTypeSelectOptions([{
      label: '--- NONE ---',
      value: undefined,
    }, ...productTypeOptions]);

    const attributeTypes = await apiRequest(
      ApiEndpoint.attributeTypeV1List,
      {
        filter: 2,
      },
      'data.list',
    ) as AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeListV1Response.AttributeType[];

    const attributes = await apiRequest(
      ApiEndpoint.attributeV1List,
      {
        filter: 2,
      },
      'data.list',
    ) as AdminBundle.Entity.API.AttributeControllerV1.AttributeListV1Response.Attribute[];

    const mappedAttributes: MappedAttribute[] = [];
    attributes.forEach((a) => {
      const type = attributeTypes.find((t) => t.attributeTypeId === a.attributeTypeId);
      if (type) {
        const m: MappedAttribute = {
          attributeId: a.attributeId,
          typeName: type.name,
          value: a.value,
        };
        mappedAttributes.push(m);
      }
    });
    setMappedAttributesList(mappedAttributes);

    const catalogList = await apiRequest(
      ApiEndpoint.gelatoApiCatalogV1CatalogList,
      {},
      'data.items',
    ) as AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.GApiCatalog[];
    setCatalogs(catalogList);

    setCatalogsSelectOptions(catalogList.map((c) => ({
      label: c.title,
      value: `${c.gApiCatalogId}`,
    })));

    const categoriesList = await apiRequest(
      ApiEndpoint.categoryV1List,
      {},
      'data.items',
    ) as AdminBundle.Entity.API.CategoryControllerV1.CategoryListV1Response.Category[];

    setCategorySelectOptions([{
      label: '--- NONE ---',
      value: undefined,
    }, ...categoriesList.map((c) => ({
      label: c.name,
      value: `${c.categoryId}`,
    }))]);

    if (id) {
      setTitle('Edit abstract product');
      setSubTitle(id);
      const response = await apiRequest(
        ApiEndpoint.abstractProductV1Get,
        {
          abstractProductId: id,
        },
        'data',
      ) as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductGetV1Response;
      setSubTitle(response.name);

      if (isDuplicateMode) {
        setNamePlaceholder(response.name);
        delete response.abstractProductId;
        response.name = '';
      }

      setEditedItem(response);
    } else {
      const firstCatalog = catalogList.length ? catalogList[0] : undefined;
      if (firstCatalog) {
        setEditedItem({ ...editedItem, gApiCatalogId: firstCatalog?.gApiCatalogId });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(abstractProductId);
  }, [abstractProductId]);

  useEffect(() => {
    if (gapiProducts) {
      const collectedAttributes: ProductAttributes[] = [];
      const collection: { [productAttribute: string]: ProductAttributes & { value: any } } = {};

      gapiProducts.forEach((gp) => {
        gp.productAttributes.forEach((pa) => {
          if (!collection[pa.productAttributeUid]) {
            collection[pa.productAttributeUid] = { ...cloneDeep(pa), values: [] };
            delete collection[pa.productAttributeUid].value;
          }
          if (!collection[pa.productAttributeUid].values.find(
            (existingValue: ProductAttributesValue) => existingValue.productAttributeValueUid === pa.value.productAttributeValueUid,
          )) {
            collection[pa.productAttributeUid].values.push(pa.value);
          }
        });
      });
      Object.keys(collection).forEach((attrName) => {
        const productAttribute = collection[attrName];
        collectedAttributes.push(productAttribute);
      });
      setProductAttributes(collectedAttributes);
    }
  }, [gapiProducts]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.abstractProductList));
  };

  const getAbstractProductId = () => {
    const id = (editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductEditV1Request).abstractProductId;

    if (id) {
      return id.toString();
    }
    return undefined;
  };

  const content = () => {
    switch (step) {
      case 1:
        return (
          <AbstractProductEditStep1
            onBack={onBackClick}
            namePlaceholder={namePlaceholder}
            productTypeSelectOptions={productTypeSelectOptions}
            catalogsSelectOptions={catalogsSelectOptions}
            categorySelectOptions={categorySelectOptions}
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <AbstractProductEditStep2
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            setStep={setStep}
            catalog={catalogs.find(
              (c) => (c.gApiCatalogId === parseInt(editedItem.gApiCatalogId as unknown as string, 10)),
            )}
          />
        );
      case 3:
        return (
          <AbstractProductEditStep3
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            setStep={setStep}
            setGapiProducts={setGapiProducts}
            gapiProducts={gapiProducts}
          />
        );
      case 4:
        return (
          <AbstractProductEditStep4
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            abstractProductId={getAbstractProductId()}
            setStep={setStep}
            gapiProducts={gapiProducts}
            productAttributes={productAttributes}
          />
        );
      case 5:
        return (
          <AbstractProductEditStep5
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            setStep={setStep}
            mappedAttributes={mappedAttributesList}
          />
        );
      case 6:
        return (
          <AbstractProductEditStep6
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            setStep={setStep}
            setGapiProducts={setGapiProducts}
            gapiProducts={gapiProducts}
          />
        );
      case 7:
        return (
          <AbstractProductEditStep7
            onSuccessfulSave={onBackClick}
            setGlobalLoading={setIsLoading}
            editedItem={editedItem as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request}
            onSubmit={setEditedItem}
            setStep={setStep}
            isDuplicateMode={isDuplicateMode !== null}
            productAttributes={productAttributes}
          />
        );
      default:
        return <h1>Step does not exist</h1>;
    }
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      {content()}
    </PageContent>
  );
};

export default AbstractProductEdit;
