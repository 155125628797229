import React from 'react';

interface OwnProps {
  text?: string;
  forInput?: string;
}

interface Props extends OwnProps {
}

const InputLabel = ({ text, forInput }: Props) => {
  if (!text) {
    return null;
  }
  return (
    <label htmlFor={forInput}>{text}</label>
  );
};

export default InputLabel;
