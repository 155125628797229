import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/store/app/types';

export const appInitialState: AppState = {

};

export const slice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {},
});

export const {} = slice.actions;

const app = slice.reducer;
export default app;
