import React, { Dispatch, SetStateAction } from 'react';

export interface OwnProps {
  onSearch: Dispatch<SetStateAction<string>>;
  onChange: Dispatch<SetStateAction<string>>;
  currentValue: string;
}

export interface Props extends OwnProps {

}

const ItemsListSearchInput = ({ onChange, onSearch, currentValue }: Props) => (
  <div className="input-group mb-3">
    <input
      type="text"
      className="form-control"
      placeholder="Search"
      aria-label="Recipient's username"
      aria-describedby="button-addon2"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onSearch(currentValue);
        }
      }}
      value={currentValue}
    />
    <div className="input-group-append">
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => {
          onSearch(currentValue);
        }}
      >
        Search
      </button>
    </div>
  </div>
);

export default ItemsListSearchInput;
