import { Field } from 'formik';
import React from 'react';
import InputDescription from 'src/component/Input/InputDescription';
import InputError from 'src/component/Input/InputError';
import InputLabel from 'src/component/Input/InputLabel';
import styles from './index.module.scss';

interface OwnProps {
  label?: string;
  name: string;
  description?: string;
  value?: string;
  disabled?: boolean;
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

interface Props extends OwnProps {
}

const InputCheckbox = (props: Props) => {
  const {
    name,
    description,
    label,
    disabled,
    value,
    validate,
  } = props;
  const inputId = `inputCheckbox_${name}`;
  return (
    <div className="form-group">
      <Field id={inputId} type="checkbox" name={name} className={styles.input} disabled={disabled} validate={validate} value={value} />
      <InputLabel forInput={inputId} text={label} />
      <InputError name={name} />
      <InputDescription description={description} />
    </div>
  );
};

export default InputCheckbox;
