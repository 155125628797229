import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import EnabledDisabledIcons from 'src/component/EnabledDisabledIcons';
import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
  item: AdminBundle.Entity.API.LandingPageCategoryControllerV1.LandingPageCategoryListV1Response.LandingPageCategory;
}

export interface Props extends OwnProps {

}

const LandingPageCategoryListItem = ({ item }: Props) => (
  <tr>
    <td>
      <EnabledDisabledIcons isEnabled={item.isEnabled} />
    </td>
    <td>{item.name}</td>
    <td>
      <div className="btn-group">
        <Link
          to={getUrl(RouteName.landingPageCategoryEdit, { landingPageCategoryId: item.landingPageCategoryId })}
          className="btn btn-sm btn-warning"
        >
          Edit
        </Link>
      </div>
    </td>
  </tr>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LandingPageCategoryListItem);
