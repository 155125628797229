import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import Spinner from 'src/component/Spinner';
import { cloneDeep } from 'lodash';
import styles from './index.module.scss';

export interface OwnProps {
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
  gapiProducts: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[];
  setGapiProducts: (products: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[]) => void;
}

export interface Props extends OwnProps {

}

const AbstractProductEditStep6 = (
  {
    editedItem,
    onSubmit,
    setStep,
  }: Props,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLocales, setSelectedLocales] = useState<string[]>([]);

  const [allLocales, setAllLocales] = useState<string[]>([]);
  const currentProductLocales: string[] = (editedItem as any).allowedLocales || [];

  const fetchLocales = async () => {
    setIsLoading(true);

    const localesResponse = await apiRequest(
      ApiEndpoint.abstractProductV1AvailableLocales,
      {
        productTypeId: editedItem.productTypeId,
      },
      'data.locales',
      'GET',
    ) as string[];

    setAllLocales([...localesResponse, ...currentProductLocales].sort());
    setSelectedLocales(cloneDeep(currentProductLocales));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLocales();
  }, []);

  if (isLoading) {
    return (<Spinner size={40} />);
  }

  const onCheckboxChange = (isChecked: boolean, locale: string) => {
    const clone = cloneDeep(selectedLocales);

    if (isChecked) {
      clone.push(locale);
    } else {
      const index = clone.indexOf(locale);
      if (index !== -1) {
        clone.splice(index, 1);
      }
    }
    setSelectedLocales(clone);
  };

  const selectAll = () => {
    setSelectedLocales(cloneDeep(allLocales));
  };

  const selectInverse = () => {
    setSelectedLocales(allLocales.filter((locale) => selectedLocales.indexOf(locale) === -1).map((p) => p));
  };

  return (
    <Formik
      initialValues={editedItem}
      onSubmit={async (values, { setSubmitting }) => {
        (values as any).allowedLocales = selectedLocales;
        onSubmit(values);
        setStep(7);
      }}
    >
      {() => (
        <Form>
          <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={() => setStep(5)} />
          <Card header="Allowed locales" className="mb-3">
            <div>
              <button type="button" className="btn btn-primary" onClick={selectAll}>Select all</button>
              &nbsp;
              <button type="button" className="btn btn-primary" onClick={selectInverse}>Select inverse</button>
            </div>
            {allLocales.map((locale) => (
              <div key={locale}>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  checked={selectedLocales.indexOf(locale) !== -1}
                  onChange={(event) => {
                    onCheckboxChange(event.target.checked, locale);
                  }}
                />
                <span>{locale}</span>
              </div>
            ))}
          </Card>
          <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={() => setStep(5)} />
        </Form>
      )}
    </Formik>
  );
};

export default AbstractProductEditStep6;
