import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import CategoryListItem from './CategoryListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const CategoryList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [categories, setCategories] = useState(
    [] as AdminBundle.Entity.API.CategoryControllerV1.CategoryListV1Response.Category[],
  );

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.categoryV1List,
        {},
        'data.items',
      ) as AdminBundle.Entity.API.CategoryControllerV1.CategoryListV1Response.Category[];
      setCategories(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const items = search
    ? categories.filter((category) => (
      (category.name || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (category.description || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || category.categoryId === parseInt(search, 10)
    ))
    : categories;

  return (
    <PageContent title="Category list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.categoryAdd)}>Add new category</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Parent Id</th>
            <th scope="col">Name</th>
            <th scope="col">Visibility</th>
            <th scope="col">Types</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {items.map((r) => (<CategoryListItem category={r} key={r.categoryId} />))}
        </tbody>
      </table>
    </PageContent>
  );
};

export default CategoryList;
