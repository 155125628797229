import React from 'react';
import { connect } from 'react-redux';
import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const TopBar = (props: Props) => (
  <nav className="navbar navbar-dark bg-dark flex-md-nowrap p-0 shadow">
    <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href={getUrl(RouteName.dashboard)}>
      Optimalprint Admin
    </a>
    <button
      className="navbar-toggler position-absolute d-md-none collapsed"
      type="button"
      data-toggle="collapse"
      data-target="#sidebarMenu"
    >
      <span className="navbar-toggler-icon" />
    </button>
    {/* <ul className="navbar-nav px-3"> */}
    {/*  <li className="nav-item text-nowrap"> */}
    {/*    <a className="nav-link" href="/logout">Sign out</a> */}
    {/*  </li> */}
    {/* </ul> */}
  </nav>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(TopBar);
