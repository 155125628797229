import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ApiEndpoint from 'src/api/endpoints';
import apiRequest from 'src/api/apiRequest';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { getUrl, RouteName } from 'src/routing';

type ClipArtCategory = AdminBundle.Entity.API.ClipArtCategoryControllerV1.ClipArtCategoryListV1Response.ClipArtCategory;
export interface OwnProps {
  category: ClipArtCategory;
  depth: number;
  browseClipArtCategory: (n: number) => void;
}

export interface Props extends OwnProps {

}

const ClipArtCategoryListItem = ({ category, depth, browseClipArtCategory }: Props) => {
  const [isDeleted, setDeleted] = useState(false);
  const browseCategory = () => {
    browseClipArtCategory(category.clipArtCategoryId);
  };
  const deleteClipArtCategory = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.clipArtCategoryV1Delete,
        { clipArtCategoryId: category.clipArtCategoryId },
        'data.status',
        'POST',
      );
      if (response === true) {
        setDeleted(true);
      }
    } catch (e) {
      setDeleted(false);
    }
  };
  if (isDeleted) return null;

  return (
    <>
      <tr>
        <td>
          {category.clipArtCategoryId}
        </td>
        <td style={{ paddingLeft: `${depth * 20}px` }}>
          <div onClick={browseCategory}>
            {category.title}
          </div>
        </td>
        <td>
          <div className="btn-group" role="group">
            <Link
              to={getUrl(RouteName.clipArtCategoryAdd, { parentClipArtCategoryId: category.parentClipArtCategoryId || 0, sortOrder: category.sortOrder + 1 })}
              className="btn btn-sm btn-warning"
            >
              Add
            </Link>
            <Link
              to={getUrl(RouteName.clipArtCategoryEdit, { categoryId: category.clipArtCategoryId })}
              className="btn btn-sm btn-warning"
            >
              Edit
            </Link>
            {category.subCategories.length === 0 && (
              <button
                className="btn btn-sm btn-warning"
                onClick={deleteClipArtCategory}
              >
                Del
              </button>
            )}
          </div>
        </td>
      </tr>
      {category.subCategories.map((r) => (
        <ClipArtCategoryListItem
          category={r as ClipArtCategory}
          depth={depth + 1}
          key={r.clipArtCategoryId}
          browseClipArtCategory={browseClipArtCategory}
        />
      ))}
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ClipArtCategoryListItem);
