import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputHidden from 'src/component/Input/InputHidden';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const AttributeTypeEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const emptyItem: AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeAddV1Request = {
    name: '',
  };

  const [
    editedItem,
    setEditedItem,
  ] = useState<AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeAddV1Request
    | AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeEditV1Request>(emptyItem);
  const [title, setTitle] = useState('Add new attribute type');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ attributeTypeId: string | undefined }>();

  const { attributeTypeId } = params;
  const fetchData = async (id?: string) => {
    setIsLoading(true);
    if (id) {
      setTitle('Edit attribute type');
      setSubTitle(id);
      const response = await apiRequest(
        ApiEndpoint.attributeTypeV1Get,
        {
          attributeTypeId: id,
        },
        'data',
      ) as AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeGetV1Response;

      setSubTitle(response.name);

      setEditedItem(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(attributeTypeId);
  }, [attributeTypeId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.attributeTypeList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={editedItem}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              attributeTypeId ? ApiEndpoint.attributeTypeV1Edit : ApiEndpoint.attributeTypeV1Add,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setEditedItem(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card className="mb-3">
              {attributeTypeId ? (
                <InputHidden name="attributeTypeId" />
              ) : null}
              <InputText
                name="name"
                label="Name"
                validate={requiredValidator}
              />
              <InputText
                name="gApiName"
                label="Gelato API name"
              />
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default AttributeTypeEdit;
