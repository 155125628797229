import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { getUrl, RouteName } from 'src/routing';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import ClipArtItem from '../ClipArtItem';

type ClipArt = AdminBundle.Entity.API.ClipArtControllerV1.ClipArtListV1Response.ClipArt;

export interface OwnProps {
  clipArtCategoryId: number;
}

export interface Props extends OwnProps {

}

const ClipArtCategory = ({ clipArtCategoryId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [clipArts, setClipArts] = useState<ClipArt[]>([]);

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.clipArtCategoryV1List,
        { clipArtCategoryId },
        'data.list',
        'POST',
      ) as ClipArt[];
      setClipArts(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  return (
    <>
      {clipArtCategoryId}
      <div className="btn-group" role="group">
        <Link
          to={getUrl(RouteName.clipArtAdd, { categoryId: clipArtCategoryId })}
          className="btn btn-sm btn-warning"
        >
          Add clip art item
        </Link>
      </div>
      {clipArts.map((clipArtItem) => (
        <ClipArtItem
          item={clipArtItem as ClipArt}
          clipArtCategoryId={clipArtCategoryId}
          key={clipArtItem.clipArtId}
        />
      ))}
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ClipArtCategory);
