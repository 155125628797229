import React from 'react';
import { connect } from 'react-redux';
import SidebarLink from 'src/component/SidebarMenu/SidebarLink';
import { RouteName } from 'src/routing';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const SidebarMenu = (props: Props) => (
  <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
    <div className="sidebar-sticky pt-3">
      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>General</span>
      </h6>
      <ul className="nav flex-column">
        <li className="nav-item">
          <SidebarLink routeName={RouteName.dashboard} text="Dashboard" />
        </li>
      </ul>

      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Url&apos;s</span>
      </h6>
      <ul className="nav flex-column">
        <li className="nav-item">
          <SidebarLink routeName={RouteName.urlMappingList} text="Url&apos;s list" />
        </li>
      </ul>

      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Categories</span>
      </h6>
      <ul className="nav flex-column">
        <SidebarLink routeName={RouteName.categoryList} text="Category list" />
        <SidebarLink routeName={RouteName.landingPageCategoryList} text="Landing pages" />
      </ul>

      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Products</span>
      </h6>
      <ul className="nav flex-column">
        <SidebarLink routeName={RouteName.productTypeList} text="Product types list" />
        <SidebarLink routeName={RouteName.attributeTypeList} text="Attribute types list" />
        <SidebarLink routeName={RouteName.attributeList} text="Attributes list" />
        <SidebarLink routeName={RouteName.abstractProductList} text="Abstract products list" />
      </ul>

      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Clip art</span>
      </h6>
      <ul className="nav flex-column">
        <SidebarLink routeName={RouteName.clipArtImport} text="Clip art import" />
        <SidebarLink routeName={RouteName.clipArtReport} text="Clip art upload report" />
      </ul>

      {/* Commented out for now, this section was never finished */}
      {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"> */}
      {/*   <span>Clip art</span> */}
      {/* </h6> */}
      {/* <ul className="nav flex-column"> */}
      {/*   <SidebarLink routeName={RouteName.clipArtCategoryList} text="Clip art categories" /> */}
      {/*   <SidebarLink routeName={RouteName.clipArtPriceTierList} text="Clip art price tiers" /> */}
      {/* </ul> */}

    </div>
  </nav>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(SidebarMenu);
