import { Field } from 'formik';
import React from 'react';
import InputDescription from 'src/component/Input/InputDescription';
import InputError from 'src/component/Input/InputError';
import InputLabel from 'src/component/Input/InputLabel';

interface OwnProps {
  label?: string;
  placeholder?: string;
  description?: string;
  name: string;
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

interface Props extends OwnProps {
}

const InputText = (
  {
    name,
    description,
    label,
    validate,
    placeholder,
  }: Props,
) => {
  const inputId = `inputText_${name}`;
  return (
    <div className="form-group">
      <InputLabel forInput={inputId} text={label} />
      <Field type="text" name={name} className="form-control" validate={validate} placeholder={placeholder} />
      <InputError name={name} />
      <InputDescription description={description} />
    </div>
  );
};

export default InputText;
