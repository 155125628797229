import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import PageContent from 'src/component/PageContent';
import ProductsAvailablePerCountry from 'src/component/ProductsAvailablePerCountry';
import ProductsDefaultPerCountry from 'src/component/ProductsDefaultPerCountry';
import { getUrl, RouteName } from 'src/routing';

export type ProductCountryAvailability = {
  allowedCountries: string[];
  defaultCountries: string[];
  isEnabled: boolean;
  productId: number;
  internalUid: string;
}

export type CountryIdSelector = {
  countryId: string;
  checked: boolean;
  hasDefaultProduct: boolean;
}

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const AbstractProductCountryAvailability = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const params = useParams<{ abstractProductId: string }>();
  const [allCountries, setAllCountries] = useState<string[]>();
  const [countriesForProducts, setCountriesForProducts] = useState<ProductCountryAvailability[]>();

  const fetchAllLocales = async () => {
    const localesResponse = await apiRequest(
      ApiEndpoint.abstractProductV1AllCountries,
      {},
      'data.countries',
      'GET',
    ) as string[];

    setAllCountries(localesResponse);
  };

  const fetchLocalesForAbstractProduct = async (abstractProductId: number) => {
    const response = await apiRequest(
      ApiEndpoint.abstractProductCountryAvailailityV1List,
      {
        abstractProductId,
      },
      'data.products',
      'GET',
    ) as ProductCountryAvailability[];

    setCountriesForProducts(response);
  };

  const fetchData = async (abstractProductId: number) => {
    setIsLoading(true);
    try {
      await fetchAllLocales();
      await fetchLocalesForAbstractProduct(abstractProductId);
      setIsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error fetching translation patterns data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(Number(params.abstractProductId));
  }, []);

  const onBackClick = () => {
    history.push(getUrl(RouteName.abstractProductList));
  };

  const saveData = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Save data now?')) {
      return;
    }

    setIsLoading(true);
    try {
      await apiRequest(
        ApiEndpoint.abstractProductCountryAvailailityV1Edit,
        {
          products: countriesForProducts?.map((productAvailability) => ({
            productId: productAvailability.productId,
            allowedCountries: productAvailability.allowedCountries,
            defaultCountries: productAvailability.defaultCountries,
          })),
        },
        'data',
        'POST',
      );
      setIsLoading(false);
      onBackClick();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const exportData = () => {
    const dataJson = new Blob([JSON.stringify(countriesForProducts)] as any as Blob[], { type: 'octet/stream' });
    const blobUrl = window.URL.createObjectURL(dataJson);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = blobUrl;
    const fileName = `abstract_product_country_availability_${params.abstractProductId}_${new Date().toISOString().replaceAll(/[:.]/g, '')}.json`;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  };

  return (
    <PageContent title="Country availability" subTitle="" isLoading={isLoading}>
      {countriesForProducts && (
        <>
          <FormConfirmButtonsBar
            onBack={onBackClick}
            onSave={saveData}
            onCustomButton={exportData}
            customButtonTitle="Export data"
          />
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <span
                className={classNames({
                  'nav-link': true,
                  active: activeTab === 0,
                  cursorPointer: true,
                })}
                onClick={() => setActiveTab(0)}
              >
                Products enabled per country
              </span>
            </li>
            <li className="nav-item">
              <span
                className={classNames({
                  'nav-link': true,
                  active: activeTab === 1,
                  cursorPointer: true,
                })}
                onClick={() => setActiveTab(1)}
              >
                Default products per country
                &nbsp;
                <span className="table-success">&nbsp; &nbsp;</span>
              </span>
            </li>
          </ul>
          {activeTab === 0 && (
            <ProductsAvailablePerCountry
              allCountries={allCountries}
              countriesForProducts={countriesForProducts}
              setCountriesForProducts={setCountriesForProducts}
            />
          )}
          {activeTab === 1 && (
            <ProductsDefaultPerCountry
              allCountries={allCountries}
              countriesForProducts={countriesForProducts}
              setCountriesForProducts={setCountriesForProducts}
            />
          )}
          <FormConfirmButtonsBar
            onBack={onBackClick}
            onSave={saveData}
            onCustomButton={exportData}
            customButtonTitle="Export data"
          />
        </>
      )}
    </PageContent>
  );
};
export default AbstractProductCountryAvailability;
