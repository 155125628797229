import React from 'react';
import { connect } from 'react-redux';

export interface OwnProps {
  title?: string;
  subTitle?: string;
}

export interface Props extends OwnProps {

}

const SectionHeader = ({ title, subTitle }: Props) => (
  <div
    className="pt-3 pb-2 mb-3 border-bottom"
  >
    {title ? <div><h1 className="h2">{title}</h1></div> : null}
    {subTitle ? <div><h2 className="h4">{subTitle}</h2></div> : null}
  </div>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(SectionHeader);
