import { Form, Formik } from 'formik';
import React from 'react';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputHidden from 'src/component/Input/InputHidden';
import InputSelect, { SelectOption } from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
  abstractProductId?: number;
  namePlaceholder?: string;
  productTypeSelectOptions: SelectOption[];
  catalogsSelectOptions: SelectOption[];
  categorySelectOptions: SelectOption[];
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
  onBack: () => void;
}

export interface Props extends OwnProps {

}

const AbstractProductEditStep1 = (
  {
    abstractProductId,
    productTypeSelectOptions,
    catalogsSelectOptions,
    categorySelectOptions,
    editedItem,
    onSubmit,
    setStep,
    onBack,
    namePlaceholder,
  }: Props,
) => (
  <Formik
    initialValues={editedItem}
    onSubmit={async (values, { setSubmitting }) => {
      const newValue = { ...editedItem, ...values };
      // eslint-disable-next-line no-self-compare
      if (editedItem.gApiCatalogId.toString() !== values.gApiCatalogId.toString()) {
        newValue.attributeFilters = [];
        newValue.attributesMapping.colorTypeAttributes = [];
        newValue.attributesMapping.paperFormatAttributes = [];
        newValue.attributesMapping.paperTypeAttributes = [];
        newValue.gApiProductIds = [];
        newValue.productPageSelectors = [];
        newValue.editorSelectors = [];
        newValue.baseInternalUid = '';
      }

      onSubmit(newValue);
      setStep(2);
    }}
  >
    {({ values }) => (
      <Form>
        <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={onBack} />
        <Card header="Main settings" className="mb-3">
          {abstractProductId ? (
            <InputHidden name="abstractProductId" />
          ) : null}
          <InputSelect
            name="productTypeId"
            options={productTypeSelectOptions}
            label="Product type"
          />
          <InputSelect
            name="categoryId"
            options={categorySelectOptions}
            label="Category"
            validate={requiredValidator}
          />
          <InputText
            name="name"
            placeholder={namePlaceholder}
            label="Name"
            validate={requiredValidator}
          />
          <InputSelect
            options={catalogsSelectOptions}
            name="gApiCatalogId"
            label="Gelato API Catalog"
            validate={requiredValidator}
          />
        </Card>
        <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={onBack} />
      </Form>
    )}
  </Formik>

);

export default AbstractProductEditStep1;
