import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { getUrl, RouteName } from 'src/routing';

type ClipArt = AdminBundle.Entity.API.ClipArtControllerV1.ClipArtListV1Response.ClipArt;
export interface OwnProps {
  item: ClipArt;
  clipArtCategoryId: number;
}

export interface Props extends OwnProps {

}

const ClipArtItem = ({ item, clipArtCategoryId }: Props) => {
  const browseCategory = () => {
  };
  return (
    <>
      <div>
        <div>
          {item.title}
        </div>
        <div onClick={browseCategory}>
          {item.imageUrl}
        </div>
        <div className="btn-group" role="group">
          <Link
            to={getUrl(RouteName.clipArtCategoryEdit, { categoryId: clipArtCategoryId, clipArtId: item.clipArtId })}
            className="btn btn-sm btn-warning"
          >
            Edit
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ClipArtItem);
