import { Form, Formik } from 'formik';
import React from 'react';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import { cloneDeep } from 'lodash';
import InputSelect from 'src/component/Input/InputSelect';
import styles from './index.module.scss';

type ProductAttributes = AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.ProductAttributes;

export interface OwnProps {
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
  abstractProductId?: string;
  gapiProducts: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[];
  productAttributes: ProductAttributes[];
}

export interface Props extends OwnProps {

}

const AbstractProductEditStep4 = (
  {
    editedItem,
    onSubmit,
    setStep,
    abstractProductId,
    gapiProducts,
    productAttributes,
  }: Props,
) => {
  const onProductPageSelectorCheckboxChange = (isChecked: boolean, typeName: string) => {
    const clone = cloneDeep(editedItem);
    const selectors = clone.productPageSelectors;

    if (isChecked) {
      selectors.push({
        typeName,
        isHidden: false,
      });
    } else {
      let index = -1;
      for (let i = 0; i < selectors.length; i += 1) {
        if (selectors[i].typeName === typeName) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        selectors.splice(index, 1);
      }
    }
    clone.productPageSelectors = selectors;
    onSubmit(clone);
  };

  const onEditorSelectorCheckboxChange = (isChecked: boolean, typeName: string) => {
    const clone = cloneDeep(editedItem);
    const selectors = clone.editorSelectors;

    if (isChecked) {
      selectors.push({
        typeName,
        isHidden: false,
      });
    } else {
      let index = -1;
      for (let i = 0; i < selectors.length; i += 1) {
        if (selectors[i].typeName === typeName) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        selectors.splice(index, 1);
      }
    }
    clone.editorSelectors = selectors;
    onSubmit(clone);
  };

  const onDesignProductSelectorCheckboxChange = (isChecked: boolean, typeName: string) => {
    const clone = cloneDeep(editedItem);
    const selectors = clone.designProductPageSelectors;

    if (isChecked) {
      selectors.push({
        typeName,
        isHidden: false,
      });
    } else {
      let index = -1;
      for (let i = 0; i < selectors.length; i += 1) {
        if (selectors[i].typeName === typeName) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        selectors.splice(index, 1);
      }
    }
    clone.designProductPageSelectors = selectors;
    onSubmit(clone);
  };

  const onDesignProductSelectorVisibilityChange = (isHidden: boolean, typeName: string) => {
    const clone = cloneDeep(editedItem);
    const selectors = clone.designProductPageSelectors;

    let index = -1;
    for (let i = 0; i < selectors.length; i += 1) {
      if (selectors[i].typeName === typeName) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      selectors.splice(index, 1);
      selectors.splice(0, 0, {
        typeName,
        isHidden,
      });
    }
    clone.designProductPageSelectors = selectors;
    onSubmit(clone);
  };

  const onEditorSelectorVisibilityChange = (isHidden: boolean, typeName: string) => {
    const clone = cloneDeep(editedItem);
    const selectors = clone.editorSelectors;

    let index = -1;
    for (let i = 0; i < selectors.length; i += 1) {
      if (selectors[i].typeName === typeName) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      selectors.splice(index, 1);
      selectors.splice(0, 0, {
        typeName,
        isHidden,
      });
    }
    clone.editorSelectors = selectors;
    onSubmit(clone);
  };

  const onProductPageSelectorVisibilityChange = (isHidden: boolean, typeName: string) => {
    const clone = cloneDeep(editedItem);
    const selectors = clone.productPageSelectors;

    let index = -1;
    for (let i = 0; i < selectors.length; i += 1) {
      if (selectors[i].typeName === typeName) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      selectors.splice(index, 1);
      selectors.splice(0, 0, {
        typeName,
        isHidden,
      });
    }
    clone.productPageSelectors = selectors;
    onSubmit(clone);
  };

  const uidSelectOptions = gapiProducts.map((u) => ({
    label: u.internalUid,
    value: u.internalUid,
  }));

  return (
    <Formik
      initialValues={editedItem}
      onSubmit={async (values, { setSubmitting }) => {
        const data = { ...editedItem, ...{ baseInternalUid: values.baseInternalUid } };

        onSubmit(data);
        setStep(5);
      }}
    >
      {({ values }) => (
        <Form>
          <FormConfirmButtonsBar
            showSubmit
            submitLabel="Next"
            onBack={() => setStep(3)}
          />
          <Card header="Default product page product" className="mb-3">
            <InputSelect name="baseInternalUid" options={uidSelectOptions} />
          </Card>
          <Card header="Product page selectors" className="mb-3">
            <div className="row">
              <div className="col">
                <strong>Display selectors on product page for:</strong>
                {productAttributes.map((a) => (
                  <div key={a.productAttributeUid}>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={!!editedItem.productPageSelectors.find((s) => s.typeName === a.productAttributeUid)}
                      onChange={(event) => {
                        onProductPageSelectorCheckboxChange(event.target.checked, a.productAttributeUid);
                      }}
                    />
                    {a.title && a.title.length ? `${a.title} (${a.productAttributeUid})` : `${a.productAttributeUid}`}
                    {editedItem.productPageSelectors.find((s) => s.typeName === a.productAttributeUid) ? (
                      <span className="ml-3">
                        <span
                          className="cursorPointer"
                          onClick={() => {
                            onProductPageSelectorVisibilityChange(false, a.productAttributeUid);
                          }}
                        >
                          <input
                            className="ml-2 mr-1"
                            type="radio"
                            value="false"
                            checked={(editedItem.productPageSelectors.find((s) => s.typeName === a.productAttributeUid))?.isHidden === false}
                          />
                          <span className="mr-3">Visible</span>
                        </span>
                        <span
                          className="cursorPointer"
                          onClick={() => {
                            onProductPageSelectorVisibilityChange(true, a.productAttributeUid);
                          }}
                        >
                          <input
                            className="mr-1"
                            type="radio"
                            value="true"
                            checked={(editedItem.productPageSelectors.find((s) => s.typeName === a.productAttributeUid))?.isHidden === true}
                          />
                          <span>Hidden</span>
                        </span>
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="col">
                <strong>Selectors order:</strong>
                {editedItem && editedItem.productPageSelectors.map((selector, index) => (
                  <div key={selector.typeName}>
                    {selector.typeName}
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <Card header="Editor selectors" className="mb-3">
            <div className="row">
              <div className="col">
                <strong>Display selectors in editor for:</strong>
                {productAttributes.map((a) => (
                  <div key={a.productAttributeUid}>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={!!editedItem.editorSelectors.find((s) => s.typeName === a.productAttributeUid)}
                      onChange={(event) => {
                        onEditorSelectorCheckboxChange(event.target.checked, a.productAttributeUid);
                      }}
                    />
                    {a.title && a.title.length ? `${a.title} (${a.productAttributeUid})` : `${a.productAttributeUid}`}
                    {editedItem.editorSelectors.find((s) => s.typeName === a.productAttributeUid) ? (
                      <span className="ml-3">
                        <span
                          className="cursorPointer"
                          onClick={() => {
                            onEditorSelectorVisibilityChange(false, a.productAttributeUid);
                          }}
                        >
                          <input
                            className="ml-2 mr-1"
                            type="radio"
                            value="false"
                            checked={(editedItem.editorSelectors.find((s) => s.typeName === a.productAttributeUid))?.isHidden === false}
                          />
                          <span className="mr-3">Visible</span>
                        </span>
                        <span
                          className="cursorPointer"
                          onClick={() => {
                            onEditorSelectorVisibilityChange(true, a.productAttributeUid);
                          }}
                        >
                          <input
                            className="mr-1"
                            type="radio"
                            value="true"
                            checked={(editedItem.editorSelectors.find((s) => s.typeName === a.productAttributeUid))?.isHidden === true}
                          />
                          <span>Hidden</span>
                        </span>
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="col">
                <strong>Selectors order:</strong>
                {editedItem && editedItem.editorSelectors.map((selector, index) => (
                  <div key={selector.typeName}>
                    {selector.typeName}
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <Card header="Design product page selectors" className="mb-3">
            <div className="row">
              <div className="col">
                <strong>Display selectors on design product page for:</strong>
                {productAttributes.map((a) => (
                  <div key={a.productAttributeUid}>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={!!editedItem.designProductPageSelectors.find((s) => s.typeName === a.productAttributeUid)}
                      onChange={(event) => {
                        onDesignProductSelectorCheckboxChange(event.target.checked, a.productAttributeUid);
                      }}
                    />
                    {a.title && a.title.length ? `${a.title} (${a.productAttributeUid})` : `${a.productAttributeUid}`}
                    {editedItem.designProductPageSelectors.find((s) => s.typeName === a.productAttributeUid) ? (
                      <span className="ml-3">
                        <span
                          className="cursorPointer"
                          onClick={() => {
                            onDesignProductSelectorVisibilityChange(false, a.productAttributeUid);
                          }}
                        >
                          <input
                            className="ml-2 mr-1"
                            type="radio"
                            value="false"
                            checked={(editedItem.designProductPageSelectors.find((s) => s.typeName === a.productAttributeUid))?.isHidden === false}
                          />
                          <span className="mr-3">Visible</span>
                        </span>
                        <span
                          className="cursorPointer"
                          onClick={() => {
                            onDesignProductSelectorVisibilityChange(true, a.productAttributeUid);
                          }}
                        >
                          <input
                            className="mr-1"
                            type="radio"
                            value="true"
                            checked={(editedItem.designProductPageSelectors.find((s) => s.typeName === a.productAttributeUid))?.isHidden === true}
                          />
                          <span>Hidden</span>
                        </span>
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="col">
                <strong>Selectors order:</strong>
                {editedItem && editedItem.designProductPageSelectors.map((selector, index) => (
                  <div key={selector.typeName}>
                    {selector.typeName}
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <FormConfirmButtonsBar
            showSubmit
            submitLabel="Next"
            onBack={() => setStep(3)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AbstractProductEditStep4;
