import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import apiRequestBlob from 'src/api/apiRequestBlob';
import ApiEndpoint from 'src/api/endpoints';
import { getUrl, RouteName } from 'src/routing';
import { AbstractProductListListItem } from 'src/view/AbstractProductList/index';

export interface OwnProps {
  item: AbstractProductListListItem;
}

export interface Props extends OwnProps {

}

const AbstractProductListItem = ({ item }: Props) => {
  const getPriceSheet = async () => {
    const response = await apiRequestBlob(
      ApiEndpoint.abstractProductV1GetPriceSheet,
      {
        abstractProductId: item.abstractProductId,
      },
      'data',
      'POST',
    ) as any;
    // const fileName = response.headers['content-disposition'].split('=')[1];
    const priceSheetsData = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blobUrl = window.URL.createObjectURL(priceSheetsData);
    window.location.href = blobUrl;
  };

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.productTypeName}</td>
      <td>{item.slug}</td>
      <td>
        <div className="btn-group" role="group">
          <Link
            to={getUrl(RouteName.abstractProductEdit, { abstractProductId: item.abstractProductId })}
            className="btn btn-sm btn-warning"
          >
            Edit
          </Link>
          <Link
            to={getUrl(RouteName.abstractProductDuplicate, { abstractProductId: item.abstractProductId })}
            className="btn btn-sm btn-warning"
          >
            Duplicate
          </Link>
          <Link
            to={getUrl(RouteName.abstractProductTranslationPattern, { abstractProductId: item.abstractProductId })}
            className="btn btn-sm btn-warning"
          >
            Translation patterns
          </Link>
          <Link
            to={getUrl(RouteName.abstractProductCountryAvailability, { abstractProductId: item.abstractProductId })}
            className="btn btn-sm btn-warning"
          >
            Country availability
          </Link>
          <div
            className="btn btn-sm btn-warning"
            onClick={getPriceSheet}
          >
            Get price
          </div>
          <Link
            className="btn btn-sm btn-warning"
            to={getUrl(RouteName.abstractProductBundles, { abstractProductId: item.abstractProductId })}
          >
            Bundles
          </Link>
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AbstractProductListItem);
