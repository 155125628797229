import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import ProductTypeListItem from 'src/view/ProductTypeList/ProductTypeListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ProductTypeList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [items, setItems] = useState(
    [] as AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeListV1Response.ProductType[],
  );

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.productTypeV1List,
        {},
        'data.list',
      ) as AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeListV1Response.ProductType[];
      setItems(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const filteredItems = search
    ? items.filter((item) => (
      (item.name || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (item.description || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || item.productTypeId === parseInt(search, 10)
    ))
    : items;

  return (
    <PageContent title="Product type list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.productTypeAdd)}>Add new product type</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Is visible</th>
            <th scope="col">Name</th>
            <th scope="col">Default quantity</th>
            <th scope="col">Is private</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((r) => (<ProductTypeListItem item={r} key={r.productTypeId} />))}
        </tbody>
      </table>
    </PageContent>
  );
};

export default ProductTypeList;
