import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

export interface OwnProps {
  header?: string | ReactElement | undefined;
  title?: string | ReactElement | undefined;
  className?: string;
}

export interface Props extends OwnProps {
  children?: any;
}

const Card = (
  {
    header,
    title,
    children,
    className,
  }: Props,
) => (
  <div className={`card ${className}`}>
    {header ? (<div className="card-header">{header}</div>) : null}
    <div className="card-body">
      {title ? (<h5 className="card-title">{title}</h5>) : null}
      {children}
    </div>
  </div>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Card);
