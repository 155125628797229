import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputCheckbox from 'src/component/Input/InputCheckbox';
import InputHidden from 'src/component/Input/InputHidden';
import InputNumber from 'src/component/Input/InputNumber';
import InputSelect from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import productTierSelectOptions from 'src/util/selectOptions/productTierSelectOptions';
import requiredIntegerValidator from 'src/validator/requiredIntegerValidator';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ProductTypeEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const emptyProductType: AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeAddV1Request = {
    defaultQuantity: 1, description: '', isPrivate: true, isVisible: true, name: '', productTier: '',
  };

  const [
    editedItem,
    setEditedItem,
  ] = useState<
    AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeAddV1Request
    | AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeEditV1Request
    >(emptyProductType);
  const [title, setTitle] = useState('Add new product type');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ productTypeId: string | undefined }>();

  const { productTypeId } = params;
  const fetchData = async (id?: string) => {
    setIsLoading(true);
    if (id) {
      setTitle('Edit product type');
      setSubTitle(id);
      const response = await apiRequest(
        ApiEndpoint.productTypeV1Get,
        {
          productTypeId: id,
        },
        'data',
      ) as AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeGetV1Response;

      setSubTitle(response.name);

      setEditedItem(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(productTypeId);
  }, [productTypeId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.productTypeList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={editedItem}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              productTypeId ? ApiEndpoint.productTypeV1Edit : ApiEndpoint.productTypeV1Add,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setEditedItem(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card className="mb-3">
              {productTypeId ? (
                <InputHidden name="productTypeId" />
              ) : null}
              <InputText
                name="name"
                label="Name"
                validate={requiredValidator}
              />
              <InputSelect
                name="productTier"
                options={productTierSelectOptions}
                label="Product tier"
              />
              <InputText
                name="description"
                label="Description"
                validate={requiredValidator}
              />
              <InputNumber
                name="defaultQuantity"
                label="Default quantity"
                validate={requiredIntegerValidator}
              />
              <InputCheckbox
                name="isVisible"
                label="Is visible"
              />
              <InputCheckbox
                name="isPrivate"
                label="Is private"
              />
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default ProductTypeEdit;
