import classNames from 'classnames';
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { CountryIdSelector, ProductCountryAvailability } from 'src/view/AbstractProductCountryAvailability';

export interface OwnProps {
}

export interface Props extends OwnProps {
  allCountries?: string[];
  countriesForProducts: ProductCountryAvailability[];
  setCountriesForProducts: (x: ProductCountryAvailability[]) => void;
}

const ProductsAvailablePerCountry = (
  {
    allCountries,
    countriesForProducts,
    setCountriesForProducts,
  }: Props,
) => {
  const [filterText, setFilterText] = useState('');

  const setCountryIdForAllProducts = (checked: boolean, countryId: string) => {
    const updatedCountryIdsForProducts = cloneDeep(countriesForProducts) as ProductCountryAvailability[];
    let defaultCountryRemoved = false;
    updatedCountryIdsForProducts.forEach((product) => {
      if (!isInFilterScope(product)) return;
      defaultCountryRemoved = toggleCountryIdInStructure(updatedCountryIdsForProducts, checked, countryId, product.productId);
    });
    if (defaultCountryRemoved) {
      updatedCountryIdsForProducts.some((product) => {
        if (product.allowedCountries.indexOf(countryId) >= 0) {
          product.defaultCountries = [countryId];
          return true;
        }
        return false;
      });
    }
    setCountriesForProducts(setDefaultCountry(updatedCountryIdsForProducts));
  };

  const toggleCountryIdInStructure = (storage: ProductCountryAvailability[], checked: boolean, countryId: string, productId: number) => {
    const product = storage.find((productAvailability) => productAvailability.productId === productId);
    let defaultCountryCleared = false;
    if (!product) return false;
    if (checked) {
      const countryIdExists = product.allowedCountries.indexOf(countryId) >= 0;
      if (!countryIdExists) product.allowedCountries.push(countryId);
    } else {
      const indexToRemove = product.allowedCountries.indexOf(countryId);
      // remove default country if requested
      if (isSelectedAsDefault(countryId, productId)) {
        const defaultCountryIdx = product.defaultCountries.indexOf(countryId);
        product.defaultCountries.splice(defaultCountryIdx, 1);
        defaultCountryCleared = true;
      }
      if (indexToRemove === -1) return false;
      product.allowedCountries.splice(indexToRemove, 1);
    }
    return defaultCountryCleared;
  };

  const isInFilterScope = (product: ProductCountryAvailability) => {
    if (!filterText) {
      return true;
    }
    const words = filterText.split(' ');
    return !words.some((word) => product.internalUid.indexOf(word) === -1);
  };

  const toggleAll = (checked: boolean) => {
    const updatedCountryIdsForProducts = cloneDeep(countriesForProducts) as ProductCountryAvailability[];
    if (checked) {
      updatedCountryIdsForProducts.forEach((product) => {
        if (!isInFilterScope(product)) return;
        product.allowedCountries = cloneDeep(allCountries || []);
      });
    } else {
      updatedCountryIdsForProducts.forEach((product) => {
        if (!isInFilterScope(product)) return;
        product.allowedCountries = [];
        product.defaultCountries = [];
      });
    }
    setCountriesForProducts(setDefaultCountry(updatedCountryIdsForProducts));
  };

  const toggleCountryId = (checked: boolean, countryId: string, productId: number) => {
    const updatedCountryIdsForProducts = cloneDeep(countriesForProducts) as ProductCountryAvailability[];
    const defaultCountryRemoved = toggleCountryIdInStructure(updatedCountryIdsForProducts, checked, countryId, productId);
    // if default country was deselected set default coutry for first available product
    if (defaultCountryRemoved) {
      updatedCountryIdsForProducts.some((product) => {
        if (product.allowedCountries.indexOf(countryId) >= 0) {
          product.defaultCountries.push(countryId);
          return true;
        }
        return false;
      });
    }
    setCountriesForProducts(setDefaultCountry(updatedCountryIdsForProducts));
  };

  const toggleAllCountryIdsForProduct = (checked: boolean, productId: number) => {
    const updatedCountryIdsForProducts = cloneDeep(countriesForProducts) as ProductCountryAvailability[];
    const productToChange = updatedCountryIdsForProducts.find((product) => product.productId === productId);
    if (!productToChange) {
      return;
    }
    if (checked) {
      productToChange.allowedCountries = cloneDeep(allCountries || []);
    } else {
      productToChange.allowedCountries = [];
      productToChange.defaultCountries = [];
    }
    setCountriesForProducts(setDefaultCountry(updatedCountryIdsForProducts));
  };

  const getCountryDataForHeader = (allProducts: ProductCountryAvailability[], filteredProducts: ProductCountryAvailability[]) => {
    const headerData: CountryIdSelector[] = [];
    allCountries?.forEach((countryId) => {
      const checked = !filteredProducts?.some((lp) => lp.allowedCountries.indexOf(countryId) === -1);
      const hasDefaultProduct = allProducts?.some((product) => product.defaultCountries.indexOf(countryId) >= 0);
      headerData.push({
        countryId,
        checked,
        hasDefaultProduct,
      });
    });
    return headerData;
  };

  const filteredProducts = filterText ? countriesForProducts?.filter((product) => isInFilterScope(product)) : countriesForProducts;

  const allCountryIdsSelected = getCountryDataForHeader(countriesForProducts, filteredProducts);

  const availableForAllCountries: boolean[] = [];
  let isAllSelected = true;
  filteredProducts?.forEach((product) => {
    const checked = product.allowedCountries.length === allCountries?.length;
    if (product.allowedCountries.length !== allCountries?.length) {
      isAllSelected = false;
    }
    availableForAllCountries.push(checked);
  });

  const isSelectedAsDefault = (countryId: string, productId: number) => {
    const product = countriesForProducts.find((productAvailability) => productAvailability.productId === productId);
    if (!product) {
      return false;
    }

    return product.defaultCountries.indexOf(countryId) !== -1;
  };

  const setDefaultCountry = (updatedCountryIdsForProducts: ProductCountryAvailability[]) => {
    const countryData = getCountryDataForHeader(updatedCountryIdsForProducts, filteredProducts);
    countryData?.forEach((country) => {
      if (!country.hasDefaultProduct) {
        updatedCountryIdsForProducts.some((product) => {
          if (product.allowedCountries.indexOf(country.countryId) >= 0) {
            product.defaultCountries.push(country.countryId);
            return true;
          }
          return false;
        });
      }
    });
    return updatedCountryIdsForProducts;
  };

  return (
    <table className="table table-bordered table-hover table-sm" style={{ fontSize: '11px' }}>
      <thead>
        <tr>
          <th>
            Product Uid
            &nbsp;
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={(event) => {
                toggleAll(event.target.checked);
              }}
            />
            &nbsp;
            <input
              type="text"
              placeholder="Search..."
              value={filterText}
              onChange={(event) => {
                setFilterText(event.target.value);
              }}
            />
          </th>
          {allCountryIdsSelected?.map((countryId, index) => (
            <th
              align="center"
              key={`key3_${index}`}
              className={classNames({
                'table-warning': !countryId.hasDefaultProduct,
              })}
            >
              {countryId.countryId}
              <br />
              <input
                type="checkbox"
                checked={countryId.checked}
                onChange={(event) => {
                  setCountryIdForAllProducts(event.target.checked, countryId.countryId);
                }}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredProducts?.map((product, index) => (
          <React.Fragment key={`key4_${index}`}>
            {index % 10 === 9 && (
              <tr key={`key5_${index}`}>
                <td>
                  <b>Product Uid</b>
                </td>
                {allCountries?.map((countryId) => (
                  <td
                    align="center"
                    key={`key6_${countryId}_${index}`}
                  >
                    <b>{index % 10 === 9 && countryId}</b>
                  </td>
                ))}
              </tr>
            )}
            <tr
              key={`key7_${index}`}
              className={classNames({
                'table-warning': !product.isEnabled,
              })}
            >
              <td style={{ whiteSpace: 'nowrap' }}>
                <input
                  type="checkbox"
                  checked={availableForAllCountries[index]}
                  onChange={(event) => {
                    toggleAllCountryIdsForProduct(event.target.checked, product.productId);
                  }}
                />
                &nbsp;
                {product.internalUid}
              </td>
              {allCountries?.map((countryId) => (
                <td
                  align="center"
                  key={`key8_${countryId}_${index}`}
                  className={classNames({
                    'table-success': isSelectedAsDefault(countryId, product.productId),
                  })}
                >
                  <input
                    title={countryId}
                    type="checkbox"
                    // disabled={isSelectedAsDefault(countryId, product.productId)}
                    checked={product.allowedCountries.indexOf(countryId) >= 0}
                    onChange={(event) => {
                      toggleCountryId(event.target.checked, countryId, product.productId);
                    }}
                  />
                </td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>

  );
};

export default ProductsAvailablePerCountry;
