import React from 'react';
import InputDescription from 'src/component/Input/InputDescription';
import InputLabel from 'src/component/Input/InputLabel';

interface OwnProps {
  label?: string;
  name: string;
  description?: string;
  value?: any;
  onChange: (value: string) => void;
  disabled?: boolean;
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

interface Props extends OwnProps {
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

const InputTextControlled = (props: Props) => {
  const {
    name,
    description,
    label,
    disabled,
    value,
    onChange,
  } = props;

  const inputId = `inputSelect_${name}`;

  return (
    <div className="form-group">
      <InputLabel forInput={inputId} text={label} />
      <input
        type="text"
        name={name}
        className="form-control"
        value={value ?? ''}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <InputDescription description={description} />
    </div>
  );
};

export default InputTextControlled;
