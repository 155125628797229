import React from 'react';
import ApiEndpoint from 'src/api/endpoints';
import PageContent from 'src/component/PageContent';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ClipArtUploadReport = (props: Props) => {
  const downloadReport = async () => {
    window.location.href = ApiEndpoint.clipArtV1GetUploadReport;
  };

  return (
    <PageContent title="Clip art upload report">
      <div className="row mb-3">
        <div className="col-12 text-left">
          <button className="btn btn-success" onClick={downloadReport}>Download report</button>
        </div>
      </div>
    </PageContent>
  );
};

export default ClipArtUploadReport;
