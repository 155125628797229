import React from 'react';
import { connect } from 'react-redux';
import PageContent from 'src/component/PageContent';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const PageNotFound = (props: Props) => (
  <PageContent title="404">
    Page not found
  </PageContent>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PageNotFound);
