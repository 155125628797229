import React from 'react';

interface OwnProps {
  description?: string;
}

interface Props extends OwnProps {
}

const InputDescription = ({ description }: Props) => (description ? (
  <small className="form-text text-muted">{description}</small>) : null);

export default InputDescription;
