import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import AbstractProductListItem from 'src/view/AbstractProductList/AbstractProductListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

export interface AbstractProductListListItem {
  abstractProductId: number;
  productTypeId: number;
  name: string;
  productTypeName: string;
  slug: string;
}

const AbstractProductList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [items, setItems] = useState(
    [] as AbstractProductListListItem[],
  );

  const fetchData = async () => {
    try {
      const productTypeList = await apiRequest(
        ApiEndpoint.productTypeV1List,
        {},
        'data.list',
      ) as AdminBundle.Entity.API.ProductTypeControllerV1.ProductTypeListV1Response.ProductType[];

      const response = await apiRequest(
        ApiEndpoint.abstractProductV1List,
        {},
        'data.list',
      ) as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductListV1Response.AbstractProduct[];

      const preparedItems: AbstractProductListListItem[] = response.map((abstractProduct) => {
        const productType = productTypeList.find((pt) => pt.productTypeId === abstractProduct.productTypeId);
        let productTypeName = 'NOT-FOUND';

        if (productType) {
          productTypeName = productType.name;
        }

        return {
          ...abstractProduct,
          productTypeName,
        };
      });

      setItems(preparedItems);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const filteredItems = search
    ? items.filter((item) => (
      (item.name || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (item.productTypeName || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    ))
    : items;

  return (
    <PageContent title="Abstract products list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.abstractProductAdd)}>Add new</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Product type</th>
            <th scope="col">Slug</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((r) => (<AbstractProductListItem item={r} key={r.abstractProductId} />))}
        </tbody>
      </table>
    </PageContent>
  );
};

export default AbstractProductList;
