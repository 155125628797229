import Axios, { AxiosRequestConfig, Method } from 'axios';
import { toast } from 'react-toastify';
import ApiEndpoint from 'src/api/endpoints';

const UNAUTHORIZED = 401;

const clientInfo = {
  platform: 5,
};

async function sendAxiosRequest(
  config: AxiosRequestConfig,
): Promise<any> {
  let result;
  delete config.adapter;
  config.withCredentials = false;

  try {
    const response = await Axios(config);

    result = response;
    delete result.config;
    delete result.request;
  } catch (err) {
    return err.response;
  }

  return result;
}

const getHeaders = () => ({
  'Content-Type': 'application/json',
});

const prepareFullUrl = (apiBaseUrl: string, url: string) => {
  if (url.indexOf('http') === 0) {
    return url;
  }
  return `${apiBaseUrl}${url}`;
};

const getBaseUrl = () => window.integrationLayer.apiBaseUrl;

const apiRequestBlob = async (
  url: ApiEndpoint,
  data: string | any,
  resultField: string,
  method: Method = 'GET',
  withCookies = true,
  maxRetries = 3,
) => new Promise(
  // eslint-disable-next-line no-async-promise-executor
  async (resolve, reject): Promise<any | any[]> => {
    const baseUrl = getBaseUrl();
    let retry = 0;

    const newUrl = prepareFullUrl(baseUrl, url);

    const options: AxiosRequestConfig = {
      method,
      responseType: 'blob',
      headers: getHeaders(),
    };

    if (method === 'POST') {
      options.data = JSON.stringify({ ...data, clientInfo });
    } else {
      options.params = data;
    }

    const isUnauthorized = (r?: { status: number }) => r && r.status === UNAUTHORIZED;
    const request = async () => {
      retry += 1;

      try {
        const result: any = await sendAxiosRequest(
          {
            url: newUrl,
            ...options,
          },
        );

        // login as a guest
        if (isUnauthorized(result)) {
          throw new Error('No authorised or no access to this area');
        }
        if (!result) {
          throw new Error(`Could not get any response from ${url}`);
        }

        if (!result) {
          throw new Error('No file received');
        } else {
          resolve(result);
        }
      } catch (e) {
        if (retry < maxRetries) {
          setTimeout(request, retry * 1000);
        } else {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`API ERROR: ${JSON.stringify(e)}`);
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    request();
  },
);

export default apiRequestBlob;
