import React, { MutableRefObject, useEffect } from 'react';
import {
  FileUploader,
  FileUploaderConfig,
  FileUploaderCallback,
  FileUploaderMessage,
} from 'file-uploader';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

export interface UploadedImageData {
  file: BaseImage;
  url: string;
}

export interface BaseImage {
  id: string;
  name: string;
  type: string;
}

interface Props {
  uploaderRef: MutableRefObject<FileUploader | undefined>;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const ApiFileUploader = ({ uploaderRef, dispatch }: Props) => {
  const { uploaderCompanionUrl } = useIntegrationLayer();

  useEffect(() => {
    const fileUploaderConfig: FileUploaderConfig = {
      core: {
        id: 'uppy-app',
        restrictions: {
          maxNumberOfFiles: 200,
          allowedFileTypes: ['image/*', '.heic'],
        },
        autoProceed: true,
        allowMultipleUploads: true,
      },
      dashboard: {
        disableStatusBar: true,
      },
      statusBar: false,
      companionUrl: uploaderCompanionUrl,
    };

    const onBackdropClick = () => {
      uploaderRef?.current?.close();
    };

    const findBackdropElement = () => {
      const elements = document.getElementsByClassName('uppy-Dashboard-overlay');
      if (elements.length) {
        return elements.item(0);
      }
      return undefined;
    };
    const attachBackdropClick = () => {
      const backdrop = findBackdropElement();
      if (backdrop) {
        backdrop.addEventListener('click', onBackdropClick);
      }
    };

    const detachBackdropClick = () => {
      const backdrop = findBackdropElement();
      if (backdrop) {
        backdrop.removeEventListener('click', onBackdropClick);
      }
    };

    const fileUploaderCallback: FileUploaderCallback = (
      message: FileUploaderMessage,
      data,
    ) => {
      switch (message) {
        case FileUploaderMessage.previewGeneratedSuccess:
          break;
        case FileUploaderMessage.previewGeneratedRemotelySuccess:
          break;
        case FileUploaderMessage.previewGeneratedError:
          break;
        case FileUploaderMessage.previewGeneratedRemotelyError:
          break;
        case FileUploaderMessage.uploadSuccess:
          if (window.APIUploaderCallbacks && window.APIUploaderCallbacks.onSuccessUpload) {
            window.APIUploaderCallbacks.onSuccessUpload(data);
          }
          break;
        case FileUploaderMessage.uploadError:
          break;
        case FileUploaderMessage.uploadStart:
          break;
        case FileUploaderMessage.uploadComplete:
          break;
        case FileUploaderMessage.openModal:
          attachBackdropClick();
          break;
        case FileUploaderMessage.closeModal:
          detachBackdropClick();
          break;
        case FileUploaderMessage.uploadProgress:
          // dispatch(sendPostMessage({
          //   type: MESSAGE_TYPE['uploader.uploadProgress'],
          //   data: {
          //     file: (data as any).file,
          //     bytesUploaded: (data as any).progress.bytesUploaded,
          //     bytesTotal: (data as any).progress.bytesTotal,
          //   },
          // }));
          break;
        default:
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploaderRef.current = new FileUploader(
      fileUploaderConfig,
      fileUploaderCallback,
    );
    // console.log('file uploader initialized');
    window.APIUploaderRef = uploaderRef;
  }, []);

  return (<></>);
};

export default connect()(ApiFileUploader);
