import React, { ChangeEvent, FormEvent } from 'react';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputLabel from 'src/component/Input/InputLabel';
import { SelectOption } from 'src/component/Input/InputSelect';
import { cloneDeep } from 'lodash';
import styles from './index.module.scss';

export interface OwnProps {
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  catalog?: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogListV1Response.GApiCatalog;
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
}

export interface Props extends OwnProps {

}

const AbstractProductEditStep2 = (
  {
    editedItem,
    onSubmit,
    setStep,
    catalog,
  }: Props,
) => {
  if (!catalog) {
    return <div>Catalog does not exist</div>;
  }

  const isSelected = (productAttributeUid: string, productAttributeValueUid?: string) => {
    if (!productAttributeValueUid) {
      return false;
    }

    const attributeFilter = editedItem.attributeFilters.find((af) => (af.productAttributeUid) === productAttributeUid);

    if (!attributeFilter) {
      return false;
    }

    return attributeFilter.productAttributeValueUids?.indexOf(productAttributeValueUid) !== -1;
  };

  const addFilterOption = (productAttributeUid: string, productAttributeValueUid?: string) => {
    if (!productAttributeValueUid) {
      return;
    }
    const cloned = cloneDeep(editedItem);
    let filter = cloneDeep(cloned.attributeFilters.find((f) => (f.productAttributeUid === productAttributeUid)));

    if (!filter) {
      filter = {
        productAttributeUid,
        productAttributeValueUids: [productAttributeValueUid],
      } as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductGetV1Response.AttributeFilter;
    } else if (filter.productAttributeValueUids) {
      filter.productAttributeValueUids.push(productAttributeValueUid);
    } else {
      filter.productAttributeValueUids = [productAttributeValueUid];
    }

    cloned.attributeFilters = cloned.attributeFilters.filter((f) => (f.productAttributeUid !== productAttributeUid));
    cloned.attributeFilters.push(filter);
    if ((cloned as unknown as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductEditV1Request).abstractProductId) {
      cloned.attributesMapping.colorTypeAttributes = [];
      cloned.attributesMapping.paperFormatAttributes = [];
      cloned.attributesMapping.paperTypeAttributes = [];
    }
    cloned.gApiProductIds = [];
    cloned.baseInternalUid = '';

    onSubmit(cloned);
  };

  const removeFilterOption = (productAttributeUid: string, productAttributeValueUid?: string) => {
    if (!productAttributeValueUid) {
      return;
    }
    const cloned = cloneDeep(editedItem);
    const filter = cloneDeep(cloned.attributeFilters.find((f) => (f.productAttributeUid === productAttributeUid)));

    if (!filter || !filter.productAttributeValueUids) {
      return;
    }

    const index = filter.productAttributeValueUids.indexOf(productAttributeValueUid);

    if (index !== -1) {
      filter.productAttributeValueUids.splice(index, 1);
    }

    cloned.attributeFilters = cloned.attributeFilters.filter((f) => (f.productAttributeUid !== productAttributeUid));

    if (filter.productAttributeValueUids.length) {
      cloned.attributeFilters.push(filter);
    }

    if ((cloned as unknown as AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductEditV1Request).abstractProductId) {
      cloned.attributesMapping.colorTypeAttributes = [];
      cloned.attributesMapping.paperFormatAttributes = [];
      cloned.attributesMapping.paperTypeAttributes = [];
    }

    cloned.gApiProductIds = [];
    cloned.baseInternalUid = '';

    onSubmit(cloned);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStep(3);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={() => setStep(1)} />
      <Card header="Product catalog filters" className="mb-3">
        {catalog?.productAttributes.map((attribute) => {
          const options = attribute.values?.map((value) => ({
            value: value.productAttributeValueUid,
            label: value.title,
          } as SelectOption));

          return (
            <div className="`col-4  d-inline-block`" key={`wrapper_${attribute.productAttributeUid}`}>
              <div className="card form-group">
                <div className="card-body">
                  <InputLabel text={attribute.title ? attribute.title : attribute.productAttributeUid} />
                  {options && options.map((option) => (
                    <div key={`${attribute.productAttributeUid}_${option.value}`}>
                      <input
                        className={styles.checkbox}
                        type="checkbox"
                        name={attribute.productAttributeUid}
                        value={option.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            addFilterOption(attribute.productAttributeUid, option.value);
                          } else {
                            removeFilterOption(attribute.productAttributeUid, option.value);
                          }
                        }}
                        checked={isSelected(attribute.productAttributeUid, option.value)}
                      />
                      {`${option.label} (${option.value})`}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </Card>
      <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={() => setStep(1)} />
    </form>
  );
};

export default AbstractProductEditStep2;
