import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import Spinner from 'src/component/Spinner';
import { cloneDeep } from 'lodash';
import styles from './index.module.scss';

export interface OwnProps {
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
  gapiProducts: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[];
  setGapiProducts: (products: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[]) => void;
}

export interface Props extends OwnProps {

}

const AbstractProductEditStep3 = (
  {
    editedItem,
    onSubmit,
    setStep,
    gapiProducts,
    setGapiProducts,
  }: Props,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productIds, setProductIds] = useState<number[]>(editedItem.gApiProductIds);

  const allProducts: AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[] = [];
  const limit = 500;

  const fetchGapiProduct = async (offset: number) => {
    setIsLoading(true);
    const filters = editedItem.attributeFilters.map((f) => ({
      name: f.productAttributeUid,
      values: f.productAttributeValueUids,
    }));

    const productsResponse = await apiRequest(
      ApiEndpoint.gelatoApiCatalogV1ProductsSearch,
      {
        gApiCatalogId: editedItem.gApiCatalogId,
        filters,
        limit,
        offset,
      },
      'data.products',
      'POST',
    ) as AdminBundle.Entity.API.GelatoApiCatalogControllerV1.GelatoApiCatalogProductsSearchV1Response.GApiProduct[];

    productsResponse.forEach((p) => {
      allProducts.push(p);
    });

    if (productsResponse.length < limit) {
      setIsLoading(false);
      setGapiProducts(allProducts);
    } else {
      await fetchGapiProduct(offset + limit);
    }
  };

  useEffect(() => {
    fetchGapiProduct(0);
  }, [JSON.stringify(editedItem.attributeFilters), editedItem.gApiCatalogId]);

  if (isLoading) {
    return (<Spinner size={40} />);
  }

  const onCheckboxChange = (isChecked: boolean, gApiProductId: number) => {
    const clone = cloneDeep(productIds);

    if (isChecked) {
      clone.push(gApiProductId);
    } else {
      const index = clone.indexOf(gApiProductId);
      if (index !== -1) {
        clone.splice(index, 1);
      }
    }
    setProductIds(clone);
  };

  const selectAll = () => {
    setProductIds(gapiProducts.map((p) => p.gApiProductId));
  };

  const selectInverse = () => {
    setProductIds(gapiProducts.filter((p) => productIds.indexOf(p.gApiProductId) === -1).map((p) => p.gApiProductId));
  };

  return (
    <Formik
      initialValues={editedItem}
      onSubmit={async (values, { setSubmitting }) => {
        values.gApiProductIds = productIds;
        onSubmit(values);
        setStep(4);
      }}
    >
      {({ values }) => (
        <Form>
          <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={() => setStep(2)} />
          <Card header="Product uid's" className="mb-3">
            <div>
              <button type="button" className="btn btn-primary" onClick={selectAll}>Select all</button>
              &nbsp;
              <button type="button" className="btn btn-primary" onClick={selectInverse}>Select inverse</button>
            </div>
            {gapiProducts.map((p) => (
              <div key={p.gApiProductId}>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  checked={productIds.indexOf(p.gApiProductId) !== -1}
                  onChange={(event) => {
                    onCheckboxChange(event.target.checked, p.gApiProductId);
                  }}
                />
                {p.internalUid}
              </div>
            ))}
          </Card>
          <FormConfirmButtonsBar showSubmit submitLabel="Next" onBack={() => setStep(2)} />
        </Form>
      )}
    </Formik>
  );
};

export default AbstractProductEditStep3;
