import React from 'react';
import { connect } from 'react-redux';
import SectionHeader from 'src/component/SectionHeader';
import Spinner from 'src/component/Spinner';
import styles from 'src/component/PageContent/index.module.scss';

export interface OwnProps {
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
}

export interface Props extends OwnProps {
  children?: any;
}

const PageContent = (
  {
    title, subTitle, children, isLoading,
  }: Props,
) => (
  <>
    {title || subTitle ? <SectionHeader title={title} subTitle={subTitle} /> : null}
    <>
      {isLoading ? (<div className={styles.spinnerWrapper}><Spinner size={40} /></div>) : children}
    </>
  </>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PageContent);
