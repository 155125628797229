import { ErrorMessage } from 'formik';
import React from 'react';
import styles from './index.module.scss';

interface OwnProps {
  name: string;
}

interface Props extends OwnProps {
}

const InputError = ({ name }: Props) => (<ErrorMessage name={name} className={styles.error} component="div" />);

export default InputError;
