import classNames from 'classnames';
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { CountryIdSelector, ProductCountryAvailability } from 'src/view/AbstractProductCountryAvailability';

export interface OwnProps {
}

export interface Props extends OwnProps {
  allCountries?: string[];
  countriesForProducts: ProductCountryAvailability[];
  setCountriesForProducts: (x: ProductCountryAvailability[]) => void;
}

const ProductsDefaultPerCountry = (
  {
    allCountries,
    countriesForProducts,
    setCountriesForProducts,
  }: Props,
) => {
  const [filterText, setFilterText] = useState('');

  const toggleCountryIdInStructure = (storage: ProductCountryAvailability[], checked: boolean, countryId: string, productId: number) => {
    const newStorage = storage.map((product) => {
      const indexToRemove = product.defaultCountries.indexOf(countryId);
      if (indexToRemove !== -1) {
        product.defaultCountries.splice(indexToRemove, 1);
      }
      return product;
    });

    const product = newStorage.find((productAvailability) => productAvailability.productId === productId);
    if (!product) {
      return newStorage;
    }
    const countryIdExists = product.defaultCountries.indexOf(countryId) >= 0;
    if (!countryIdExists) product.defaultCountries.push(countryId);
    return newStorage;
  };

  const isInFilterScope = (product: ProductCountryAvailability) => {
    if (!filterText) {
      return true;
    }
    const words = filterText.split(' ');
    return !words.some((word) => product.internalUid.indexOf(word) === -1);
  };

  const toggleCountryId = (checked: boolean, countryId: string, productId: number) => {
    let updatedCountryIdsForProducts = cloneDeep(countriesForProducts) as ProductCountryAvailability[];
    updatedCountryIdsForProducts = toggleCountryIdInStructure(updatedCountryIdsForProducts, checked, countryId, productId);
    setCountriesForProducts(updatedCountryIdsForProducts);
  };

  const filteredProducts = filterText ? countriesForProducts?.filter((product) => isInFilterScope(product)) : countriesForProducts;

  const allCountryIdsSelected: CountryIdSelector[] = [];
  allCountries?.forEach((countryId) => {
    const checked = !filteredProducts?.some((lp) => lp.defaultCountries.indexOf(countryId) === -1);
    const hasDefaultProduct = countriesForProducts?.some((product) => product.defaultCountries.indexOf(countryId) >= 0);
    allCountryIdsSelected.push({
      countryId,
      checked,
      hasDefaultProduct,
    });
  });

  const isEnabledPerCountryId = (countryId: string, productId: number) => {
    const product = countriesForProducts.find((productAvailability) => productAvailability.productId === productId);
    if (!product) {
      return false;
    }

    return product.allowedCountries.indexOf(countryId) !== -1;
  };

  return (
    <table className="table table-bordered table-hover table-sm" style={{ fontSize: '11px' }}>
      <thead>
        <tr>
          <th>
            <input
              type="text"
              placeholder="Search..."
              value={filterText}
              onChange={(event) => {
                setFilterText(event.target.value);
              }}
            />
          </th>
          {allCountryIdsSelected?.map((countryId) => (
            <th
              align="center"
              key={`acis_${countryId.countryId}`}
              className={classNames({
                'table-warning': !countryId.hasDefaultProduct,
              })}
            >
              {countryId.countryId}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredProducts?.map((product, index) => (
          <React.Fragment key={`key0_${index}`}>
            {index % 10 === 9 && (
              <tr key={`key1_${index}`}>
                <td>
                  <b>Product Uid</b>
                </td>
                {allCountries?.map((countryId) => (
                  <td align="center" key={`key11_${countryId}_${index}`}>
                    <b>{index % 10 === 9 && countryId}</b>
                  </td>
                ))}
              </tr>
            )}
            <tr>
              <td
                style={{ whiteSpace: 'nowrap' }}
                className={classNames({
                  'table-warning': !product.isEnabled,
                })}
              >
                {product.internalUid}
              </td>
              {allCountries?.map((countryId) => (
                <td
                  align="center"
                  key={`key2_${index}_${countryId}`}
                  className={classNames({
                    'table-success': product.defaultCountries.indexOf(countryId) >= 0,
                  })}
                >
                  <input
                    title={countryId}
                    type="checkbox"
                    disabled={!isEnabledPerCountryId(countryId, product.productId)}
                    checked={product.defaultCountries.indexOf(countryId) >= 0}
                    onChange={(event) => {
                      toggleCountryId(event.target.checked, countryId, product.productId);
                    }}
                  />
                </td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default ProductsDefaultPerCountry;
