import React from 'react';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import { cloneDeep } from 'lodash';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import { SelectOption } from 'src/component/Input/InputSelect';
import { MappedAttribute } from 'src/view/AbstractProductEdit/index';

export interface OwnProps {
  editedItem: AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductAddV1Request;
  mappedAttributes: MappedAttribute[];
  onSubmit: (data: any) => void;
  setStep: (step: number) => void;
}

export interface Props extends OwnProps {

}

const AbstractProductEditStep5 = (
  {
    editedItem,
    onSubmit,
    setStep,
    mappedAttributes,
  }: Props,
) => {
  const selectOptions: SelectOption[] = mappedAttributes
    .filter((mo) => editedItem.internalAttributesIds.indexOf(mo.attributeId) === -1)
    .map((mo) => ({
      label: `${mo.typeName}:${mo.value}`,
      value: mo.attributeId.toString(),
    }));

  const onAddClick = (selectedOption?: string) => {
    if (!selectedOption) {
      return;
    }
    const clone = cloneDeep(editedItem);
    if (selectedOption) {
      clone.internalAttributesIds.push(parseInt(selectedOption, 10));
    }
    onSubmit(clone);
  };
  const onRemoveClick = (index: number) => {
    const clone = cloneDeep(editedItem);
    clone.internalAttributesIds.splice(index, 1);
    onSubmit(clone);
  };

  return (
    <>
      <FormConfirmButtonsBar onNext={() => setStep(6)} onBack={() => setStep(4)} />
      <Card header="Product internal attributes" className="mb-3">
        <strong>Add attribute:</strong>
        <div className="row">
          {selectOptions.map((option, index) => (
            <div className="col-3" key={`attribute_${option.label}${option.value}`}>
              <div
                className="alert alert-secondary alert-dismissible fade show"
              >
                {`${option.label}`}
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    onAddClick(option.value);
                  }}
                >
                  <span aria-hidden="true">+</span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <hr />
        <strong>Selected attributes:</strong>
        {editedItem.internalAttributesIds.map((id, index) => {
          const type = mappedAttributes.find((mt) => mt.attributeId === id);
          return (
            <div className="alert alert-secondary alert-dismissible fade show" key={`attribute_${id}`}>
              {`${type?.typeName}:${type?.value}`}
              <button
                type="button"
                className="close"
                onClick={() => {
                  onRemoveClick(index);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          );
        })}
      </Card>
      <FormConfirmButtonsBar onNext={() => setStep(6)} onBack={() => setStep(4)} />
    </>
  );
};

export default AbstractProductEditStep5;
