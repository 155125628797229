import React from 'react';
import styles from 'src/component/Spinner/index.module.scss';

export interface OwnProps {
  size?: number;
}

export interface Props extends OwnProps {

}

const Spinner = ({ size }: Props) => {
  let spinnerSize = '40px';
  let borderSize = '40px';

  if (size) {
    spinnerSize = `${size}px`;
    borderSize = `${size / 10}px`;
  }

  return (
    <div
      className={styles.loader}
      style={{
        width: spinnerSize,
        height: spinnerSize,
        borderWidth: borderSize,
        borderTopWidth: borderSize,
      }}
    />
  );
};

export default Spinner;
