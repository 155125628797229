import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
  item: AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeListV1Response.AttributeType;
}

export interface Props extends OwnProps {

}

const AttributeTypeListItem = ({ item }: Props) => (
  <tr>
    <td>{item.name}</td>
    <td>{item.gApiName}</td>
    <td>
      <div className="btn-group" role="group">
        <Link
          to={getUrl(RouteName.attributeTypeEdit, { attributeTypeId: item.attributeTypeId })}
          className="btn btn-sm btn-warning"
        >
          Edit
        </Link>
      </div>
    </td>
  </tr>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AttributeTypeListItem);
