export default [
  { value: '0', label: 'Other' },
  { value: '1', label: 'Industry' },
  { value: '2', label: 'Occasion' },
  { value: '3', label: 'Color' },
  { value: '4', label: 'Element' },
  { value: '5', label: 'Symbol' },
  { value: '6', label: 'System' },
  { value: '7', label: 'Design Style' },
  { value: '8', label: 'Gender' },
  { value: '9', label: 'Age' },
  { value: '10', label: 'Theme' },
];
