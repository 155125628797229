import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputCheckbox from 'src/component/Input/InputCheckbox';
import InputHidden from 'src/component/Input/InputHidden';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const LandingPageCategoryEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const empty: AdminBundle.Entity.API.LandingPageCategoryControllerV1.ILandingPageCategorySaveV1Request = {
    isEnabled: true, landingPageCategoryId: undefined, name: '',
  };
  const [
    item,
    setItem,
  ] = useState<AdminBundle.Entity.API.LandingPageCategoryControllerV1.ILandingPageCategorySaveV1Request>(empty);
  const [title, setTitle] = useState('Add new landing page');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ landingPageCategoryId: string }>();

  const { landingPageCategoryId } = params;
  const fetchData = async (itemId?: string) => {
    setIsLoading(true);
    if (itemId) {
      setTitle('Edit landing page');
      setSubTitle(itemId);
      const response = await apiRequest(
        ApiEndpoint.landingPageCategoryV1Get,
        {
          landingPageCategoryId: itemId,
        },
        'data',
      ) as AdminBundle.Entity.API.LandingPageCategoryControllerV1.ILandingPageCategorySaveV1Request;

      setSubTitle(response.name);
      setItem(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(landingPageCategoryId);
  }, [landingPageCategoryId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.landingPageCategoryList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={item}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              ApiEndpoint.landingPageCategoryV1Save,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setItem(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card className="mb-3">
              {values.landingPageCategoryId ? (
                <InputHidden name="landingPageCategoryId" />
              ) : null}
              <InputText
                name="name"
                label="Category name"
                validate={requiredValidator}
              />
              <InputCheckbox
                name="isEnabled"
                label="Is Enabled"
              />
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default LandingPageCategoryEdit;
