import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import UrlMappingListItem from 'src/view/UrlMappingList/UrlMappingListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const UrlMappingList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [routes, setRoutes] = useState(
    [] as AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingListV1Response.UrlMapping[],
  );

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.urlMappingV1List,
        {},
        'data.list',
      ) as AdminBundle.Entity.API.UrlMappingControllerV1.UrlMappingListV1Response.UrlMapping[];
      setRoutes(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const items = search
    ? routes.filter((route) => (
      (route.url || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (route.service || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    ))
    : routes;

  return (
    <PageContent title="Url mapping list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.urlMappingAdd)}>Add new url</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Enabled</th>
            <th scope="col">Url</th>
            <th scope="col">Service name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((r) => (<UrlMappingListItem urlMapping={r} key={r.urlMappingId} />))}
        </tbody>
      </table>
    </PageContent>
  );
};

export default UrlMappingList;
