import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import {
  Formik, Form,
} from 'formik';
import requiredValidator from 'src/validator/requiredValidator';

type ClipArtCategoryAddRequest = AdminBundle.Entity.API.ClipArtPriceTierControllerV1.ClipArtPriceTierAddV1Request;

interface RouteUrlParams {
  priceTierId: string;
}

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const emptyPrieTier = {
  title: '',
};

const CategoryEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState('Add new clip art price tier');
  const [subTitle, setSubTitle] = useState('');
  const [priceTier, setPriceTier] = useState<ClipArtCategoryAddRequest>(emptyPrieTier);
  const params = useParams<RouteUrlParams>();

  const fetchData = async (priceTierId?: string) => {
    setIsLoading(false);

    if (priceTierId) {
      setTitle(priceTierId);
      setSubTitle(priceTierId);
      setPriceTier(emptyPrieTier);
    }
  };

  useEffect(() => {
    fetchData(params.priceTierId);
  }, []);

  const onBackClick = () => {
    history.push(getUrl(RouteName.clipArtPriceTierList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={priceTier}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              ApiEndpoint.clipArtPriceTierV1Add,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card header="Clip art price tier" className="mb-3">
              <InputText
                name="title"
                label="Price tier title"
                validate={requiredValidator}
              />
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default CategoryEdit;
