import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  color?: string;
}

const Icon = (props: Props) => {
  const { children, color, className } = props;
  return (
    <div
      className={cn({
        badge: true,
        [`badge-${color}`]: true,
        [styles.badge]: true,
        [`${className}`]: true,
      })}
    >
      {children}
    </div>
  );
};

export default Icon;
