import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import AttributeTypeListItem from 'src/view/AttributeTypeList/AttributeTypeListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const AttributeTypeList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [items, setItems] = useState(
    [] as AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeListV1Response.AttributeType[],
  );

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.attributeTypeV1List,
        {
          filter: 2,
        },
        'data.list',
      ) as AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeListV1Response.AttributeType[];
      setItems(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const filteredItems = search
    ? items.filter((item) => (
      (item.name || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || (item.gApiName || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      || item.attributeTypeId === parseInt(search, 10)
    ))
    : items;

  return (
    <PageContent title="Product attribute types list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.attributeTypeAdd)}>Add new</Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Gelato API Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((r) => (<AttributeTypeListItem item={r} key={r.attributeTypeId} />))}
        </tbody>
      </table>
    </PageContent>
  );
};

export default AttributeTypeList;
