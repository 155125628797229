import React from 'react';
import IconCancel from 'src/component/Icon/IconCancel';
import IconCheck from 'src/component/Icon/IconCheck';
import styles from './index.module.scss';

export interface OwnProps {
  isEnabled: boolean;
  label?: string;
}

export interface Props extends OwnProps {

}

const EnabledDisabledIcons = ({ isEnabled, label }: Props) => (
  <span className={styles.wrapper}>
    {isEnabled
      ? (<IconCheck color="success" className={styles.icon} />)
      : (<IconCancel color="danger" className={styles.icon} />)}
    {label ? <span className={styles.label}>{label}</span> : null}
  </span>
);

export default EnabledDisabledIcons;
