import React from 'react';
import { connect } from 'react-redux';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';

type PriceTier = AdminBundle.Entity.API.ClipArtPriceTierControllerV1.ClipArtPriceTierListV1Response.PriceTier;
export interface OwnProps {
  priceTier: PriceTier;
}

export interface Props extends OwnProps {

}

const ClipArtCategoryListItem = ({ priceTier }: Props) => (
  <>
    <tr>
      <td>
        {priceTier.clipArtPriceTierId}
      </td>
      <td>
        {priceTier.title}
      </td>
      <td />
    </tr>
  </>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ClipArtCategoryListItem);
