import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import ItemsListSearchInput from 'src/component/ItemsListSearchInput';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import ClipArtPriceTierListItem from './ClipArtPriceTierListItem';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ClipArtPriceTierList = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [categories, setCategories] = useState(
    [] as AdminBundle.Entity.API.ClipArtPriceTierControllerV1.ClipArtPriceTierListV1Response.PriceTier[],
  );

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        ApiEndpoint.clipArtPriceTierV1List,
        {},
        'data.data',
        'POST',
      ) as AdminBundle.Entity.API.ClipArtPriceTierControllerV1.ClipArtPriceTierListV1Response.PriceTier[];
      setCategories(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const items = search
    ? categories.filter((category) => (
      (category.title || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    ))
    : categories;

  const getPriceSheet = async () => {
    const response = await apiRequest(
      ApiEndpoint.clipArtPriceTierV1GetPriceSheet,
      {},
      'data',
      'GET',
    ) as Blob;
    const translationsData = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blobUrl = window.URL.createObjectURL(translationsData);
    window.location.href = blobUrl;
  };

  return (
    <PageContent title="Clip art price tier list" isLoading={isLoading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={getUrl(RouteName.clipArtPriceTierAdd)}>Add price tier</Link>
          &nbsp;
          <button className="btn btn-success" onClick={getPriceSheet}>Get price sheet</button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Title</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((r) => (<ClipArtPriceTierListItem priceTier={r} key={r.clipArtPriceTierId} />))}
            </tbody>
          </table>
        </div>
      </div>
    </PageContent>
  );
};

export default ClipArtPriceTierList;
